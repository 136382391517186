import React, { useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useStore } from 'react-redux';
import getInjectors from 'redux/utils/reducerInjectors';
/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
function injectReducer({ key, reducer, getKey, createReducer, }) {
    return (WrappedComponent) => {
        const ReducerInjector = (props) => {
            const store = useStore();
            if (!store || !store.injectedReducers) {
                throw new Error('Invalid store format for injectReducer');
            }
            const [ready] = useState(() => {
                /** We need to execute that only once and before 'willMount' so not using effects */
                const realKey = getKey ? getKey(props) : key;
                const realReducer = createReducer ? createReducer(props) : reducer;
                getInjectors(store).injectReducer(realKey, realReducer);
                return true;
            });
            if (ready) {
                // eslint-disable-next-line react/jsx-props-no-spreading
                return React.createElement(WrappedComponent, Object.assign({}, props));
            }
            return React.createElement(React.Fragment, null);
        };
        ReducerInjector.displayName = `@Reducer(${WrappedComponent.displayName
            || WrappedComponent.name
            || 'Component'})`;
        ReducerInjector.WrappedComponent = WrappedComponent;
        return hoistNonReactStatics(ReducerInjector, WrappedComponent);
    };
}
export default injectReducer;
