import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { ApplicationRoutes } from 'containers/System/routes';
import signUpIcon from 'assets/images/signup-icon.png';
import signInIcon from 'assets/images/trans-lock.png';
import newProductIcon from 'assets/images/new-product-icon.png';
import { isAdminDomain } from 'utils/forceAdmin';
import { setFlagAfterSSRRender } from 'utils/setAfterSSR';
const PublicMenu = () => {
    const showAdmin = setFlagAfterSSRRender(isAdminDomain);
    return (React.createElement("div", { className: "navbar navbar-custom", role: "navigation" },
        React.createElement("div", { className: "navbar-collapse collapse" },
            React.createElement("ul", { className: "nav navbar-nav" },
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.aboutUs }, "About Us ")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.services }, " Services")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.courtInformation }, " Court Information")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.contact }, " Contact")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.login }, " Request an Appearance")),
                React.createElement(Dropdown, { as: "li", className: "input-btn" },
                    React.createElement(Dropdown.Toggle, { as: "a", role: "button", id: "toggle-1" },
                        React.createElement("img", { src: signInIcon, alt: "Login \u2013 Appearance Attorney", title: "Login \u2013 Appearance Attorney" }),
                        React.createElement("span", null, "Login")),
                    React.createElement(Dropdown.Menu, { className: "dropdown-menu" },
                        React.createElement("div", { className: "arrow-up" }),
                        React.createElement(Dropdown.Item, { as: "li" },
                            React.createElement(Link, { to: ApplicationRoutes.login }, "Request an Appearance")),
                        React.createElement(Dropdown.Item, { as: "li" },
                            React.createElement(Link, { to: ApplicationRoutes.whyJoin }, "Appearance Attorney")),
                        showAdmin
                            && React.createElement(Dropdown.Item, { as: "li" },
                                React.createElement(Link, { className: "admin-link", to: ApplicationRoutes.adminLogin }, "Administrator")))),
                React.createElement(Dropdown, { as: "li", className: "input-btn" },
                    React.createElement(Dropdown.Toggle, { as: "a", role: "button", id: "toggle-2" },
                        React.createElement("img", { src: signUpIcon, alt: "Sign Up", title: "Sign Up" }),
                        React.createElement("span", null, "Sign Up")),
                    React.createElement(Dropdown.Menu, { className: "dropdown-menu" },
                        React.createElement("div", { className: "arrow-up" }),
                        React.createElement(Dropdown.Item, { as: "li" },
                            React.createElement(Link, { to: ApplicationRoutes.clientSignup }, "I Need Appearance Coverage")),
                        React.createElement(Dropdown.Item, { as: "li" },
                            React.createElement(Link, { to: ApplicationRoutes.attorneySignup }, "Appearance Attorney")))),
                React.createElement("li", { className: "input-btn dropdown", style: {
                        margin: "3px 8px 8px 584px",
                        height: "26px",
                        paddingTop: "3px",
                        paddingLeft: "11px",
                        paddingRight: "11px"
                    } },
                    React.createElement("a", { href: "https://lawbeez.com", target: "_blank", rel: "noreferrer", style: { fontSize: "11px" } },
                        React.createElement("span", null, "Hire Freelance Attorney"),
                        React.createElement("img", { src: newProductIcon, alt: "Hire Freelance Attorney", title: "Hire Freelance Attorney", style: {
                                position: "fixed",
                                marginTop: "-5px",
                                marginLeft: "8px"
                            } })))))));
};
export default PublicMenu;
