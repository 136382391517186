import { initialState as initialAuthState } from './auth/initialState';
import { initialState as initialNotificationsState } from './notifications/initialState';
// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.ReactSnapRedux;
// Allow the passed state to be garbage-collected
delete window.ReactSnapRedux;
export const initialState = preloadedState || {
    root: {
        auth: initialAuthState,
        notifications: initialNotificationsState,
    },
};
