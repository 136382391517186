import { actionsCountLong } from 'components/Header/formatActions';
import { ApplicationRoutes } from 'containers/System/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
const WelcomeMessage = ({ name, events }) => (React.createElement("div", { className: "welcome-msg" },
    React.createElement("p", null,
        React.createElement("span", { style: { marginRight: 5 } }, "Welcome"),
        name,
        !!events && React.createElement(Link, { className: "badge badge-events badge-events-desktop", to: ApplicationRoutes.events, title: actionsCountLong(events) }, events)),
    !!events && (React.createElement(Link, { className: "badge badge-events badge-events-mobile", to: ApplicationRoutes.events, title: actionsCountLong(events) }, actionsCountLong(events)))));
WelcomeMessage.defaultProps = {
    name: 'Unknown User',
};
export default WelcomeMessage;
