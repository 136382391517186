import { Main } from 'components/Main';
import Page from 'components/Page';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
const AppearanceDetailsRaw = React.lazy(() => import(/* webpackChunkName: "ClientAppearanceDetails" */ './AppearanceDetails'));
export const AppearanceResultDetails = () => (React.createElement(Page, { title: "Appearance", description: "Appearance" },
    React.createElement(Main, { sidebar: true },
        React.createElement("div", { className: "container-fluid att-main no-pad-r" },
            React.createElement("div", { className: "no-pad attorney-wrap " },
                React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
                    React.createElement(AppearanceDetailsRaw, null)))))));
