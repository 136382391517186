import { Main } from 'components/Main';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
import Page from 'components/Page';
const AdminAccountPage = React.lazy(() => import(/* webpackChunkName: "AdminAccountPage" */ './Page'));
export const AdminAccount = () => (React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
    React.createElement(Page, { title: "Admin Account Settings" },
        React.createElement(Main, { sidebar: true },
            React.createElement("div", { className: "container-fluid att-main no-pad" },
                React.createElement("div", { className: "no-pad attorney-wrap " },
                    React.createElement(AdminAccountPage, null)))))));
