var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { axiosInstance } from 'client/axiosInstance';
import { API_PATHES } from 'client/constants';
import Page from 'components/Page';
import { Unsuccess } from 'components/Unsuccess';
import { ApplicationRoutes } from 'containers/System/routes';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
import { Main } from 'components/Main';
import { generatePath, Redirect, useRouteMatch } from 'react-router';
import { extractError } from 'utils/extractError';
export const rejectAppearanceEffect = (attorneyId, clientId, appearanceId) => {
    const [usaStates, setResult] = React.useState({ isFetching: true });
    React.useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            setResult({ isFetching: true });
            const axios = axiosInstance();
            try {
                yield axios.get(API_PATHES.attAppearances.mailReject
                    .replace('{appearanceId}', appearanceId)
                    .replace('{clientId}', clientId)
                    .replace('{attorneyId}', attorneyId));
                setResult({ isFetching: false, data: true });
            }
            catch (e) {
                setResult({ isFetching: false, error: extractError(e, 'Could not reject appearance') });
            }
        });
        fetchData();
    }, [setResult, attorneyId, clientId, appearanceId]);
    return usaStates;
};
export const MailRejectAppearance = () => {
    const match = useRouteMatch();
    if (match) {
        const { attorneyId, clientId, appearanceId } = match.params;
        const result = rejectAppearanceEffect(attorneyId, clientId, appearanceId);
        const successUrl = generatePath(ApplicationRoutes.attorneyAvailableAppearanceRejected, { selectedId: appearanceId });
        return (React.createElement(Page, { title: "Reject Appearance" },
            React.createElement(Main, { sidebar: true },
                React.createElement("div", { className: "container-fluid att-main no-pad-r" },
                    React.createElement("div", { className: "no-pad attorney-wrap " },
                        React.createElement("div", { className: "page-heading" },
                            result.isFetching && React.createElement("h2", null, "Please wait trying to reject appearance"),
                            result.error && React.createElement("h2", null, "Appearance rejection error")),
                        result.isFetching && React.createElement(Spinner, null),
                        result.error && React.createElement(Unsuccess, { message: result.error.message }),
                        result.data && React.createElement(Redirect, { to: successUrl }))))));
    }
    return React.createElement(Redirect, { to: ApplicationRoutes.root });
};
