import { UserRole } from 'redux/auth/constants';
import { createSelector } from 'reselect';
export const selectLogin = (state) => state.root.auth;
export const selectLocation = (state) => (state.router ? state.router.location : '');
export const selectIsLoggingIn = createSelector(selectLogin, (state) => state.isLoading);
export const selectIsLoggedIn = createSelector(selectLogin, (state) => state.isLoggedIn);
export const selectLoginError = createSelector(selectLogin, (state) => state.error);
export const selectExpiresAt = createSelector(selectLogin, (state) => state.expiresAt);
export const selectUserFullName = createSelector(selectLogin, (state) => state.fullName);
export const selectUserRoles = createSelector(selectLogin, (state) => state.roles);
export const selectUserRoleIsAnyAdmin = createSelector(selectUserRoles, (roles) => roles.includes(UserRole.ADMIN) || roles.includes(UserRole.ADMIN_LIMITED));
export const selectUserIsAA = createSelector(selectUserRoles, (roles) => roles.includes(UserRole.ATTORNEY));
export const selectUserIsClient = createSelector(selectUserRoles, (roles) => roles.includes(UserRole.CLIENT));
export const selectUserRoleFlags = createSelector(selectUserRoleIsAnyAdmin, selectUserIsAA, selectUserIsClient, (isAdmin, isAA, isClient) => ({
    isAdmin,
    isAA,
    isClient,
}));
