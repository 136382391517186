import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getAuthorization } from 'client/authStorage';
import { API_PATHES, CONTENT_TYPE } from 'client/constants';
import { getEnv } from 'environments';
import { Logger } from 'logger';
let onNonAuthorised;
export const setNonAuthorisedCallback = (callback) => {
    onNonAuthorised = callback;
};
/* eslint-disable no-underscore-dangle */
export const axiosInstance = (xToken = null) => {
    const authData = getAuthorization();
    const token = xToken || (authData && authData.token);
    const tokenAttachment = token ? { 'X-Auth-Token': token } : {};
    const headers = Object.assign({ 'Content-Type': CONTENT_TYPE, Accept: CONTENT_TYPE }, tokenAttachment);
    const instance = axios.create({
        headers,
        baseURL: getEnv().apiDomain,
    });
    const retryCondition = (error) => false && error;
    // return !error.response || error.response.status === 504;
    axiosRetry(instance, {
        retryCondition,
        retries: 0,
    });
    instance.interceptors.response.use((response) => response, (error) => {
        const originalRequest = error.config;
        if (originalRequest) {
            if (error.response) {
                // if we change password of the current user, our refresh token becomes invalid, so we need to logout current user by force
                if (originalRequest.url === API_PATHES.auth.refresh && error.response.status >= 400) {
                    Logger.debug('Refresh token failed', error.response);
                    if (onNonAuthorised) {
                        onNonAuthorised();
                    }
                    return Promise.reject(error);
                }
                if (error.response.status === 401 && !originalRequest._retry && !originalRequest.url.endsWith(API_PATHES.auth.login)) {
                    Logger.debug('Original request unauthorized, refreshing token', originalRequest);
                    if (onNonAuthorised) {
                        onNonAuthorised();
                    }
                    return Promise.reject(error);
                }
            }
            else if (!error.status && error.message === 'Network Error') {
                if (typeof navigator.onLine !== 'undefined' && !navigator.onLine) {
                    // eslint-disable-next-line no-param-reassign
                    error.response = {
                        status: 800,
                        data: {
                            message: 'Device seems to be offline, check your network connection',
                        },
                    };
                }
                else {
                    // eslint-disable-next-line no-param-reassign
                    error.response = {
                        status: 800,
                        data: {
                            message: 'Please check your network connection',
                        },
                    };
                }
            }
        }
        return Promise.reject(error);
    });
    return instance;
};
