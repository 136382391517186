import { connectRouter } from 'connected-react-router';
import { Logger } from 'logger';
import { combineReducers, } from 'redux';
import { reducer as authReducer } from './auth/reducer';
import { reducer as notificationsReducer } from './notifications/reducer';
let storedHistory;
const rootReducer = combineReducers({
    auth: authReducer,
    notifications: notificationsReducer,
});
export function createReducer(injectedReducers, history) {
    storedHistory = history || storedHistory;
    if (!storedHistory) {
        Logger.error('Root reduced never received history object');
    }
    const result = combineReducers(Object.assign(Object.assign({ root: rootReducer }, (storedHistory ? { router: connectRouter(storedHistory) } : {})), injectedReducers));
    return result;
}
