import { Main } from 'components/Main';
import Page from 'components/Page';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
const ViewInvoiceDetailsPage = React.lazy(() => import(/* webpackChunkName: "ViewInvoiceDetails" */ './ViewInvoiceDetails'));
export const ViewInvoiceDetails = () => (React.createElement(Page, { title: "Invoice", description: "Invoice" },
    React.createElement(Main, { sidebar: true },
        React.createElement("div", { className: "container-fluid att-main no-pad" },
            React.createElement("div", { className: "no-pad attorney-wrap" },
                React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
                    React.createElement(ViewInvoiceDetailsPage, null)))))));
