import { withFormik } from 'formik';
import * as Yup from 'yup';
export const withContactForm = (requireCaptcha) => withFormik({
    mapPropsToValues: () => ({
        username: '',
        lastName: '',
        mail: '',
        message: '',
        recaptcha: '',
        subject: '',
    }),
    validationSchema: Yup.object().shape({
        mail: Yup.string().email('Should be an email')
            .required('Mail is required'),
        username: Yup.string().required('Name is required'),
        lastName: Yup.string(),
        subject: Yup.string().required('Subject is required'),
        message: Yup.string().required('Message is required'),
        // recaptcha: isDebug ? Yup.string() : Yup.string().required('You need to pass a check'),
        recaptcha: !requireCaptcha ? Yup.string() : Yup.string().required('You need to pass a check'),
    }),
    handleSubmit({ username, lastName, subject, message, recaptcha, mail, }, { props, setSubmitting, setStatus }) {
        const { submit } = props;
        setSubmitting(true);
        setStatus('');
        submit({
            username: lastName ? [username, lastName].join(' ') : username,
            lastName,
            subject,
            message,
            recaptcha: recaptcha || 'Trust me Im human',
            mail,
        });
    },
});
