import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectUserRoles } from 'redux/auth/selectors';
export const CheckRole = ({ children, fallback, role }) => {
    const currentRoles = useSelector(selectUserRoles);
    const hasPermission = React.useMemo(() => {
        if (Array.isArray(role)) {
            return !!role.find((r) => currentRoles.findIndex((cr) => cr === r) !== -1);
        }
        return currentRoles.findIndex((cr) => cr === role) !== -1;
    }, [currentRoles, role]);
    if (hasPermission) {
        return React.createElement(React.Fragment, null, children);
    }
    return React.createElement(React.Fragment, null, fallback);
};
