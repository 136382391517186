import React, { useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useStore } from 'react-redux';
import getInjectors from './sagaInjectors';
/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
 * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
 *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
 *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 * @param {array} [args] Arguments passed to the saga once called
 * By default your saga will receive
 *   - component props
 *   - action
 * If defined, the saga will receive those args instead of the component props
 */
export default ({ key, saga, mode, args, getKey, }) => (WrappedComponent) => {
    const InjectSaga = (props) => {
        const store = useStore();
        if (!store || !store.injectedReducers) {
            throw new Error('Invalid store format for injectReducer');
        }
        const [state] = useState(() => {
            /** We need to execute that only once and before 'willMount' so not using effects */
            const keyOut = getKey ? getKey(props) : key;
            const injectorsOut = getInjectors(store);
            return { keyOut, injectorsOut };
        });
        const [ready, setReady] = useState(false);
        React.useEffect(() => {
            const { injectSaga } = state.injectorsOut;
            const injectedArgs = args || [props];
            injectSaga(state.keyOut, { saga, mode }, ...injectedArgs);
            setReady(true);
            return () => {
                const { ejectSaga } = state.injectorsOut;
                ejectSaga(state.keyOut);
            };
        }, []);
        if (ready) {
            // eslint-disable-next-line react/jsx-props-no-spreading
            return React.createElement(WrappedComponent, Object.assign({}, props));
        }
        return React.createElement(React.Fragment, null);
    };
    InjectSaga.displayName = `@Saga(${WrappedComponent.displayName
        || WrappedComponent.name
        || 'Component'})`;
    InjectSaga.WrappedComponent = WrappedComponent;
    return hoistNonReactStatics(InjectSaga, WrappedComponent);
};
