import logoUrl from 'assets/logo.png';
import { getEnv } from 'environments';
export const jsonLDData = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Attorneys in Motion',
    image: `${getEnv().host}${logoUrl}`,
    telephone: '800-991-3903',
    email: 'info@attorneysinmotion.com',
    address: {
        '@type': 'PostalAddress',
        streetAddress: '8465 W Sahara Avenue',
        addressLocality: 'Suite 111,626, Las Vegas',
        addressRegion: 'CA',
        postalCode: '89117',
    },
    url: `${getEnv().host}/`,
};
