import { ContentWrapper } from 'components/ContentWrapper';
import Page from 'components/Page';
import { ApplicationRoutes } from 'containers/System/routes';
import * as React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
export const NotFound = () => (React.createElement(Page, { title: "Not Found", description: "Schedule your appearance coverage anytime from any device!", keywords: "not found" },
    React.createElement(Helmet, null,
        React.createElement("meta", { name: "robots", content: "noindex" }),
        React.createElement("meta", { name: "googlebot", content: "noindex" })),
    React.createElement(ContentWrapper, { className: "col-lg-6 login-wrap" },
        React.createElement("div", { className: "page-heading", style: { textAlign: 'center' } },
            React.createElement("h1", null, "Server Error: 404 (Page Not Found)")),
        React.createElement("div", { className: "col-lg-12 col-md-12 col-sm-12 login-form" },
            React.createElement("p", null, "Page you are trying to access is not available"),
            React.createElement("p", null,
                "Perhaps you would like to go to our",
                ' ',
                React.createElement(Link, { to: ApplicationRoutes.root }, "home page?"))))));
