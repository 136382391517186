import { ErrorText } from 'components/Forms/ErrorText';
import { FieldInput } from 'components/Forms/FieldInput';
import { Unsuccess } from 'components/Unsuccess';
import { MIN_PASSWORD_LENGTH } from 'constants/passwords';
import { ApplicationRoutes } from 'containers/System/routes';
import { getEnv } from 'environments';
import { ErrorMessage, withFormik } from 'formik';
import { resetOnExit } from 'hooks/callOnLeave';
import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginRequestAction, resetAction } from 'redux/auth/actions';
import { selectIsLoggingIn, selectLoginError } from 'redux/auth/selectors';
import { bindCheckbox } from 'utils/bindCheckbox';
import { isDebug } from 'utils/isDebug';
import * as Yup from 'yup';
import './overrides.css';
const InnerForm = (props) => {
    const { recapcha, values, status, handleSubmit, isSubmitting, setSubmitting, setErrors, setStatus, setFieldValue, } = props;
    const serverError = useSelector(selectLoginError);
    const serverWaiting = useSelector(selectIsLoggingIn);
    const handleCheckbox = bindCheckbox(setFieldValue);
    // Errors updater
    React.useEffect(() => {
        if (!serverWaiting && isSubmitting) {
            setSubmitting(false);
        }
        if (serverError && serverError.errors) {
            setErrors(serverError.errors);
        }
        if (serverError) {
            setStatus(serverError.message);
        }
        else {
            setStatus(null);
        }
    }, [serverError, serverWaiting, serverError, isSubmitting]);
    resetOnExit(resetAction);
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit },
            status && React.createElement(Unsuccess, { message: status }),
            React.createElement("div", { className: "lg-top" },
                React.createElement("div", { className: "lg-frm ifl" },
                    React.createElement(FieldInput, { title: "Email", name: "login", type: "email", autoComplete: "username email", wrapperClass: "control-group", inputClass: "input-box", required: true })),
                React.createElement("div", { className: "lg-frm sfr" },
                    React.createElement(FieldInput, { title: "Password", name: "password", type: "password", inputClass: "input-box", wrapperClass: "control-group", autoComplete: "current-password", required: true }))),
            React.createElement("div", { className: "lg-bot" },
                React.createElement("div", { className: "lg-b ifl" },
                    React.createElement("div", { className: "keep-b" },
                        React.createElement("label", null,
                            React.createElement("input", { type: "checkbox", name: "rememberMe", onChange: handleCheckbox, checked: values.rememberMe }),
                            ' ',
                            "Keep me signed in"))),
                React.createElement("div", { className: "lg-b sfr" },
                    React.createElement(Link, { className: "fgt_pwd", to: ApplicationRoutes.recoverPassword }, "Forgot Password"))),
            recapcha && (React.createElement(ReCAPTCHA, { sitekey: getEnv().reCaptchaKey, onChange: (v) => {
                    setFieldValue('recapcha', v || '');
                } })),
            React.createElement(ErrorMessage, { name: "recapcha" }, (err) => React.createElement(ErrorText, { error: err })),
            React.createElement("div", { className: `re-btn ${serverWaiting ? 'loading locked' : ''}` },
                React.createElement("div", { className: "", style: { float: 'left' } }),
                React.createElement("input", { className: "login_btn s-btn", name: "login", type: "submit", value: "SIGN IN", disabled: serverWaiting })))));
};
export const UnconnectedLoginForm = withFormik({
    mapPropsToValues: (props) => ({
        login: props.initial.login || '',
        password: props.initial.password || '',
        rememberMe: props.initial.rememberMe || false,
        recapcha: '',
        requestLogin: props.requestLogin,
    }),
    validationSchema: (props) => Yup.object().shape({
        rememberMe: Yup.boolean(),
        login: Yup.string().email('Username should be an email')
            .required('Username is required'),
        password: Yup.string().label('Password').trim().min(MIN_PASSWORD_LENGTH)
            .required(),
        recapcha: (isDebug || !props.recapcha) ? Yup.string() : Yup.string().required('You need to pass a check'),
    }),
    handleSubmit({ login, rememberMe, password, recapcha, }, { props, setSubmitting, setStatus }) {
        const redirectTo = window.location.search.startsWith('?returnTo=') ? window.location.search.substr(10) : null;
        const { requestLogin } = props;
        setSubmitting(true);
        setStatus('');
        requestLogin({
            redirectTo,
            login,
            password,
            rememberMe,
            recapcha,
        });
    },
})(InnerForm);
const mapDispatchToProps = (dispatch) => ({
    requestLogin: (data) => dispatch(loginRequestAction.request(data)),
});
export const LoginForm = connect(null, mapDispatchToProps)(UnconnectedLoginForm);
