import { CheckRole } from 'components/CheckRole';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { ApplicationRoutes } from 'containers/System/routes';
import './admin-menu.css';
import { UserRole } from 'redux/auth/constants';
const AdminSideBar = () => (React.createElement(Accordion, null,
    React.createElement("ul", { className: "menu-left-bx admin-style" },
        React.createElement(Accordion.Toggle, { as: "li", eventKey: "admin-menu-0", className: "expand" },
            React.createElement("a", { className: "with-inner" },
                React.createElement("span", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminManageAppearances }, "Appearances")),
                React.createElement("div", { className: "acc_icon_expand" }))),
        React.createElement(Accordion.Collapse, { eventKey: "admin-menu-0" },
            React.createElement(React.Fragment, null,
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminManageLog }, "Logs")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.messages }, "Messages")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminManageInbox }, "Notifications")))),
        React.createElement("li", null,
            React.createElement(Link, { to: ApplicationRoutes.adminManageDueAppearances }, "Payment Due Appearances")),
        React.createElement(Accordion.Toggle, { as: "li", eventKey: "admin-menu-1", className: "expand" },
            React.createElement("a", { className: "with-inner" },
                React.createElement("span", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminManageClientList }, "Client Accounts")),
                React.createElement("div", { className: "acc_icon_expand" }))),
        React.createElement(Accordion.Collapse, { eventKey: "admin-menu-1" },
            React.createElement(React.Fragment, null,
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminInvoiceHistory }, "Client Invoices")))),
        React.createElement("li", null,
            React.createElement(Link, { to: ApplicationRoutes.adminManageAAList }, "AA Accounts")),
        React.createElement(Accordion.Toggle, { as: "li", eventKey: "admin-menu-2", className: "expand" },
            React.createElement("a", null,
                React.createElement("span", null, "Fees and Locations"),
                React.createElement("div", { className: "acc_icon_expand" }))),
        React.createElement(Accordion.Collapse, { eventKey: "admin-menu-2" },
            React.createElement(React.Fragment, null,
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminManageStandardFees }, "Standard AA Fees")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminNonStandardFees }, "Non-standard fees")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminManageState }, "State List")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminManageCounty }, "County List")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminManageCourts }, "Court List")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminManageCoupon }, "Promotional Codes")))),
        React.createElement(CheckRole, { role: [UserRole.ADMIN], fallback: null },
            React.createElement("li", null,
                React.createElement(Link, { to: ApplicationRoutes.adminAAPayroll }, "AA Payroll"))),
        React.createElement(Accordion.Toggle, { as: "li", eventKey: "admin-menu-3", className: "expand" },
            React.createElement("a", null,
                React.createElement(CheckRole, { role: [UserRole.ADMIN], fallback: null },
                    React.createElement("span", null, "Account Settings")),
                React.createElement(CheckRole, { role: [UserRole.ADMIN_LIMITED], fallback: null },
                    React.createElement("span", null, "Blog Settings")),
                React.createElement("div", { className: "acc_icon_expand" }))),
        React.createElement(Accordion.Collapse, { eventKey: "admin-menu-3" },
            React.createElement(React.Fragment, null,
                React.createElement(CheckRole, { role: [UserRole.ADMIN], fallback: null },
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.adminAccountEmails }, "Admin Email Accounts and Password")),
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.adminEmailTemplates }, "Email Templates"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminBlog }, "Blog")),
                React.createElement("li", null,
                    React.createElement(Link, { to: ApplicationRoutes.adminBlogCategory }, "Blog Categories")))),
        React.createElement("li", null,
            React.createElement(Link, { to: ApplicationRoutes.logout }, "Logout")))));
export default AdminSideBar;
