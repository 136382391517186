import React from 'react';
import TwitterChat from 'components/TwitterChat';
const Tweets = () => (React.createElement("div", { className: "col-lg-4 col-md-4" },
    React.createElement("div", { className: "our-cont" },
        React.createElement("div", { className: "cont-heading" },
            React.createElement("h4", null, "View our recent tweets"),
            React.createElement("p", null, "Follow us on Twitter and stay up to date on the latest news!")),
        React.createElement("div", { className: "tweet-box" },
            React.createElement(TwitterChat, null)))));
export default Tweets;
