import { getAuthorization } from 'client/authStorage';
const inStorage = getAuthorization() || {
    token: '', expiresAt: 0, roles: [], fullName: '',
};
export const initialState = {
    isLoading: false,
    isLoggedIn: inStorage.expiresAt > Date.now(),
    roles: inStorage.roles,
    fullName: inStorage.fullName,
    expiresAt: inStorage.expiresAt,
    token: inStorage.token,
};
