import { DateTime, FixedOffsetZone } from 'luxon';
export const DEFAULT_TIMEZONE_LONG = '+00:00'; // 'xxx' format
export const DEFAULT_TIMEZONE_SHORT = '+00'; // 'x' format
export const DEFAULT_ZONE = FixedOffsetZone.utcInstance; // UTC;
/**
 * Removes time and timezone info, creating date in local timezone at 00:00:00, essentially preserving date only
 * Applies tz timezone as 'display' timezone
 * Used to pass to native datepickers as they need Date object
 */
export const readDateOnlyFromStr = (d, tz = DEFAULT_ZONE) => {
    if (d) {
        const dt = DateTime.fromISO(d, { zone: tz }); // This reads date from string and sets it's display TZ
        const onlyDate = DateTime.fromFormat(dt.toFormat('yyyy-MM-dd'), 'yyyy-MM-dd');
        return onlyDate.toJSDate();
    }
    return undefined;
};
/**
 * Prepares DateTime object in timezone that will be used for display and format
 */
export const dateInDisplayTZ = (d, tz = DEFAULT_ZONE) => DateTime.fromJSDate(d, { zone: tz });
/**
 * Prepares DateTime object in timezone that will be used for display and format
 */
export const parseInDisplayTZ = (d, tz = DEFAULT_ZONE) => DateTime.fromISO(d, { zone: tz });
/**
 * Removes time and timezone info, creating date in local timezone at 00:00:00, essentially preserving date only
 * Applies tz timezone as 'display' timezone
 * Used to pass to native datepickers as they need Date object
 */
export const readDateOnly = (d, tz = DEFAULT_ZONE) => {
    if (d) {
        const dt = dateInDisplayTZ(d, tz); // This reads date from string and sets it's display TZ
        const onlyDate = DateTime.fromFormat(dt.toFormat('yyyy-MM-dd'), 'yyyy-MM-dd');
        return onlyDate.toJSDate();
    }
    return undefined;
};
/**
 * Outputs date only without shifting timezone when needed only to transfer datepicker Date value to date
 * @param date
 */
export const apiDateFromDatepicker = (date) => (date ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') : undefined);
/**
 * Formats dates and times in desired timezone
 * @param date
 * @param tz
 */
export const shortDate = (date, tz = DEFAULT_ZONE) => (date ? dateInDisplayTZ(date, tz).toFormat('MMMM d, yyyy') : '');
export const shorterDate = (date, tz = DEFAULT_ZONE) => (date ? dateInDisplayTZ(date, tz).toFormat('MMM d, yyyy') : '');
export const smallDate = (date, tz = DEFAULT_ZONE) => (date ? dateInDisplayTZ(date, tz).toFormat('yyyy-MM-dd') : '');
export const longDate = (date, tz = DEFAULT_ZONE) => (date ? dateInDisplayTZ(date, tz).toFormat('MMMM d, yyyy h:mm a') : '');
export const longDateWSec = (date, tz = DEFAULT_ZONE) => (date ? dateInDisplayTZ(date, tz).toFormat('MMMM d, yyyy h:mm:ss a') : '');
export const timeOfDate = (date, tz = DEFAULT_ZONE) => (date ? dateInDisplayTZ(date, tz).toFormat('h:mm a') : '');
export const minutesOfDate = (date, tz = DEFAULT_ZONE) => (date ? dateInDisplayTZ(date, tz).toFormat('m') : '');
export const hours12OfDate = (date, tz = DEFAULT_ZONE) => (date ? dateInDisplayTZ(date, tz).toFormat('h') : '');
export const meridiemOfDate = (date, tz = DEFAULT_ZONE) => (date ? dateInDisplayTZ(date, tz).toFormat('a') : '');
/* export const makeFullDate = (date: Date, h: string, m: string, pm: string) => {
  if (date && typeof h !== 'undefined' && typeof m !== 'undefined') {
    const f = 'yyyy-MM-dd hh:mm a x';
    try {
      // console.log(`${format(date, 'yyyy-MM-dd')} ${h}:${m} ${pm ? 'PM' : 'AM'} ${format(date, 'xx')}`)
      return parse(`${format(date, 'yyyy-MM-dd')} ${h}:${m} ${pm ? 'PM' : 'AM'} ${format(date, 'xx')}`, f, new Date());
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  }
  return null;
}; */
// eslint-disable-next-line max-len,@typescript-eslint/no-unused-vars
export const formatFullDate = (date, h, m, pm, tz = DEFAULT_ZONE) => {
    if (date && typeof h !== 'undefined' && typeof m !== 'undefined') {
        try {
            const dt = DateTime.fromJSDate(date);
            const onlyDate = DateTime.fromFormat(`${dt.toFormat('yyyy-MM-dd')} ${h}:${m} ${pm ? 'PM' : 'AM'}`, 'yyyy-MM-dd h:m a');
            // console.log(onlyDate.toISO(), `${dt.toFormat('yyyy-MM-dd')} ${h}:${m} ${pm ? 'PM' : 'AM'}`);
            return onlyDate.toFormat('yyyy-MM-dd\' \'HH:mm:ss');
            // eslint-disable-next-line no-empty
        }
        catch (e) {
        }
    }
    return undefined;
};
/*
(window as any).readDateOnly = readDateOnly;
(window as any).dateInDisplayTZ = dateInDisplayTZ;
(window as any).apiDateFromDatepicker = apiDateFromDatepicker;
*/
