import { IS_SSR } from 'constants/isssr';
import * as React from 'react';
import { prerenderedLoadable } from 'utils/prefetch';
export const OlarkWidget = () => {
    const [ready, setReady] = React.useState(false);
    React.useEffect(() => {
        setTimeout(() => setReady(!IS_SSR && true), 2000);
    }, []);
    if (!ready) {
        return null;
    }
    const OlarkWidgetRaw = prerenderedLoadable(() => import(/* webpackChunkName: "OlarkWidget" */ './widget'));
    return (React.createElement(React.Suspense, { fallback: false },
        React.createElement(OlarkWidgetRaw, null)));
};
