import React from 'react';
import facebookIcon from 'assets/images/social/facebook-icon-ft.png';
import linedinIcon from 'assets/images/social/linked-icon-ft.png';
import twitterIcon from 'assets/images/social/tweet-icon-ft.png';
const Copyright = () => {
    const year = new Date().getFullYear();
    return (React.createElement("div", { className: "copyright-bx" },
        React.createElement("p", null,
            "Copyright ",
            year,
            " \u00B7 All rights reserved - Get updates:"),
        React.createElement("ul", { className: "footer-social-icon" },
            React.createElement("li", null,
                React.createElement("a", { href: "https://www.facebook.com/pages/Attorneys-in-Motion/142426379301888", target: "_blank", rel: "noreferrer noopener" },
                    React.createElement("img", { src: facebookIcon, alt: "Follow Attorneys in Motion on Facebook", title: "Follow Attorneys in Motion on Facebook" }))),
            React.createElement("li", null,
                React.createElement("a", { href: "https://twitter.com/AttysinMotion", target: "_blank", rel: "noreferrer noopener" },
                    React.createElement("img", { src: twitterIcon, alt: "Follow Attorneys in Motion on Twitter", title: "Follow Attorneys in Motion on Twitter" }))),
            React.createElement("li", null,
                React.createElement("a", { href: "http://www.linkedin.com/company/attorneys-in-motion", target: "_blank", rel: "noreferrer noopener" },
                    React.createElement("img", { src: linedinIcon, alt: "Follow Attorneys in Motion on LinkedIn", title: "Follow Attorneys in Motion on LinkedIn" }))))));
};
export default Copyright;
