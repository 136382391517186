var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { axiosInstance } from 'client/axiosInstance';
import { API_PATHES } from 'client/constants';
import { Logger } from 'logger';
import * as React from 'react';
import { extractError } from 'utils/extractError';
const COURT_LIST_VAR = 'court_count';
const COURT_LIST_EXPIRE = 60 * 60 * 24 * 5 * 1000; // 5 days
let courtListCount = { value: 15011, fetchedAt: 0 };
try {
    const str = localStorage.getItem(COURT_LIST_VAR);
    if (str) {
        courtListCount = JSON.parse(str);
    }
}
catch (e) {
    Logger.warn('Court list total never fetched yet');
}
export const initialCoutCountValue = courtListCount;
export const getCourtListCount = (setResult) => React.useEffect(() => {
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        setResult({ isFetching: true, data: courtListCount });
        const axios = axiosInstance();
        try {
            const result = yield axios.get(API_PATHES.places.courtList, { params: { limit: 1 } });
            const data = {
                value: result.data.total,
                fetchedAt: Date.now(),
            };
            setResult({ isFetching: false, data });
            try {
                localStorage.setItem(COURT_LIST_VAR, JSON.stringify(data));
            }
            catch (e) {
                Logger.warn('Local storage not available');
            }
            courtListCount = data;
        }
        catch (e) {
            setResult({ isFetching: false, data: courtListCount, error: extractError(e, 'Could not read court list') });
        }
    });
    setResult({ isFetching: false, data: courtListCount });
    if (courtListCount.fetchedAt + COURT_LIST_EXPIRE < Date.now()) {
        fetchData();
    }
}, []);
