var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AUTH_VARIABLE } from 'client/constants';
import { IS_SSR } from 'constants/isssr';
import { ENV_KEY, setEnv } from 'environments';
import { Logger } from 'logger';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
let internalStorage;
export const getAuthorization = () => {
    if (internalStorage) {
        return internalStorage;
    }
    try {
        const json = localStorage.getItem(AUTH_VARIABLE);
        if (json) {
            internalStorage = JSON.parse(json);
            return internalStorage;
        }
        return undefined;
    }
    catch (e) {
        Logger.debug('Stored auth data is invalid', e);
        return undefined;
    }
};
export const storeAuthorizationOnlyLocally = (token) => {
    internalStorage = token;
    try {
        localStorage.setItem(AUTH_VARIABLE, JSON.stringify(token));
    }
    catch (e) {
        Logger.debug('Could not store auth data', e);
    }
};
export const storeAuthorizationLocally = (token) => {
    storeAuthorizationOnlyLocally(token);
    if (Storage) {
        Storage.set({
            key: AUTH_VARIABLE,
            value: JSON.stringify(token),
        });
    }
};
export const clearInMemoryAuthStorage = () => {
    internalStorage = undefined;
};
export const clearAuthStorage = () => {
    clearInMemoryAuthStorage();
    try {
        localStorage.removeItem(AUTH_VARIABLE);
        localStorage.removeItem('unicorn');
        if (Storage) {
            Storage.remove({
                key: AUTH_VARIABLE,
            });
        }
    }
    catch (e) {
        Logger.debug('Could not delete auth data', e);
    }
};
export const initAuthStorage = () => __awaiter(void 0, void 0, void 0, function* () {
    if (Storage && !IS_SSR) {
        const json = yield Storage.get({
            key: AUTH_VARIABLE,
        });
        if (json && json.value) {
            storeAuthorizationOnlyLocally(JSON.parse(json.value));
        }
        const env = yield Storage.get({
            key: ENV_KEY,
        });
        if (env && env.value) {
            setEnv(env.value, false);
        }
        return true;
    }
    return true;
});
