import { ContentWrapper } from 'components/ContentWrapper';
import Page from 'components/Page';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
import { prerenderedLoadable } from 'utils/prefetch';
const ContactUsPageRaw = prerenderedLoadable(() => import(/* webpackChunkName: "ContactUsPage" */ './Page'));
export const ContactUsPage = () => (React.createElement(Page, { title: "Contact Us | Court Appearance Professionals", description: "Special Appearance attorneys for Bankruptcy, Criminal, Civil, Local Counsel, Family, Traffic and More. Call Us Today: 800-991-3903" },
    React.createElement(ContentWrapper, null,
        React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
            React.createElement(ContactUsPageRaw, null)))));
