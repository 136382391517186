import { IS_SSR } from 'constants/isssr';
import { GA_ENABLED } from 'environments';
import ReactGA from 'react-ga';
const getLOHandler = () => {
    // eslint-disable-next-line no-underscore-dangle
    window._loq = window._loq || [];
    // eslint-disable-next-line no-underscore-dangle
    return window._loq;
};
export const statLoTag = (tag, starit = false, overwriteExisting = false) => {
    if (IS_SSR) {
        return;
    }
    // Samples: https://help.luckyorange.com/article/126-tagging-with-javascript
    getLOHandler().push(['tag', tag, starit, overwriteExisting]);
};
export const userDataToLog = (userData) => {
    if (IS_SSR) {
        return;
    }
    // https://help.luckyorange.com/article/41-passing-in-custom-user-data
    getLOHandler().push(['custom', userData]);
};
export const statPageView = (path, title) => {
    if (IS_SSR || !GA_ENABLED) {
        return;
    }
    ReactGA.pageview(path, undefined, title);
};
export const statFormSubmit = (title) => {
    if (IS_SSR) {
        return;
    }
    getLOHandler().push(['submit_form', title, true]);
};
