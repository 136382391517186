import Carousel3d from 'components/Carousel3d';
import { services } from 'constants/services';
import { ServiceType } from 'constants/serviceType';
import { ApplicationRoutes } from 'containers/System/routes';
import React from 'react';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
const Block2 = ({ scrollPosition }) => (React.createElement("div", { className: "container-fluid no-pad" },
    React.createElement("div", { className: "service-portion" },
        React.createElement("div", { className: "col-lg-12 no-pad" },
            React.createElement("div", { className: "our-service" },
                React.createElement("h1", null, "Appearance Attorney Services"),
                React.createElement("p", null, "Attorneys in Motion provides appearance attorneys for all types of law and all types of appearances!")),
            React.createElement("div", { id: "wrap" },
                React.createElement("div", { id: "showcase", className: "noselect" },
                    React.createElement(Carousel3d, { yRadius: 48, autoPlay: true, bringToFront: true }, services.filter((s) => s.id !== ServiceType.Telephonic).map((item) => (React.createElement(Link, { to: ApplicationRoutes.services },
                        React.createElement("div", { className: "showcase-item slid-box" },
                            React.createElement("div", { className: "slid-img" },
                                React.createElement(LazyLoadImage, { alt: item.name, scrollPosition: scrollPosition, src: item.img })),
                            React.createElement("div", { className: "slid-txt" },
                                React.createElement("h3", null, item.name.toUpperCase()),
                                React.createElement("p", null, item.text))))))))),
            React.createElement("div", { className: "service-link" },
                React.createElement(Link, { to: ApplicationRoutes.services }, "View All"))))));
export default trackWindowScroll(Block2);
