import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
/**
 * Resets modal on unmounts and any route changes
 */
export const callOnLeave = (resetCall) => {
    React.useEffect(() => () => resetCall(), [useLocation()]);
};
/**
 * Same but for redux action
 */
export const resetOnExit = (exitAction) => {
    const dispatch = useDispatch();
    callOnLeave(() => dispatch(exitAction()));
};
