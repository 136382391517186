import { IS_SSR } from 'constants/isssr';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux/auth/selectors';
import PrivateMenu from './PrivateMenu';
import PublicMenu from './PublicMenu';
const DesktopMenu = ({ unreadEvents }) => {
    const authed = useSelector(selectIsLoggedIn);
    const [showAuthed, setShowAuthed] = React.useState(false);
    React.useEffect(() => {
        if (!IS_SSR && authed !== showAuthed) {
            setShowAuthed(authed);
        }
    }, [authed, showAuthed]);
    if (showAuthed) {
        return React.createElement(PrivateMenu, { unreadEvents: unreadEvents });
    }
    return React.createElement(PublicMenu, null);
};
export default DesktopMenu;
