import * as React from 'react';
import Page from 'components/Page';
import { Main } from 'components/Main';
import { Spinner } from 'components/Spinner';
const ViewMessageRaw = React.lazy(() => import(/* webpackChunkName: "ViewMessagePage" */ './ViewMessage'));
// in legacy there're no title and description
export const ViewMessage = () => (React.createElement(Page, { title: "Notification & Messages", description: "Manage Inbox" },
    React.createElement(Main, { sidebar: true },
        React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
            React.createElement(ViewMessageRaw, null)))));
