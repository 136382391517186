export var ApplicationRoutes;
(function (ApplicationRoutes) {
    // public
    ApplicationRoutes["root"] = "/";
    ApplicationRoutes["notFound"] = "404.html";
    ApplicationRoutes["login"] = "/login";
    ApplicationRoutes["logout"] = "/logout";
    ApplicationRoutes["recoverPassword"] = "/password/recovery";
    ApplicationRoutes["setPassword"] = "/password/recovery/:hash";
    ApplicationRoutes["adminForgotPassword"] = "/admin/forgot/password";
    ApplicationRoutes["adminChangePassword"] = "/admin/change_pwd/:hash";
    ApplicationRoutes["sitemap"] = "/sitemap.html";
    ApplicationRoutes["blog"] = "/blog";
    ApplicationRoutes["privacyPolicy"] = "/content/privacy-policy";
    ApplicationRoutes["termsAndConditions"] = "/content/terms-condition";
    ApplicationRoutes["attorneyLogin"] = "/content/why-join";
    ApplicationRoutes["adminLogin"] = "/admin/login";
    ApplicationRoutes["clientSignUp"] = "/signup";
    ApplicationRoutes["attorneySignUp"] = "/attorney/signup";
    ApplicationRoutes["protected"] = "/protected";
    ApplicationRoutes["aboutUs"] = "/content/about-us";
    ApplicationRoutes["services"] = "/content/fees";
    ApplicationRoutes["contact"] = "/content/contact";
    ApplicationRoutes["courtInformation"] = "/content/court-info";
    ApplicationRoutes["whyJoin"] = "/content/why-join";
    ApplicationRoutes["faq"] = "/content/faq";
    ApplicationRoutes["security"] = "/content/security";
    // attorney specific pages
    ApplicationRoutes["attorneyAccount"] = "/attorney/account";
    ApplicationRoutes["attorneySignup"] = "/attorney/signup";
    ApplicationRoutes["attorneyDashboard"] = "/attorney/dashboard";
    ApplicationRoutes["attorneyAvailableAppearance"] = "/attorney/available/appearance";
    ApplicationRoutes["attorneyAvailableAppearanceRejected"] = "/attorney/available/appearance?rejected";
    ApplicationRoutes["attorneyAvailableAppearanceDetails"] = "/attorney/view/available/appearance/:selectedId";
    ApplicationRoutes["attorneyManageAppearance"] = "/attorney/manage/appearance";
    ApplicationRoutes["attorneyManageAppearanceDetails"] = "/attorney/view/case/details/:selectedId";
    ApplicationRoutes["attorneyManageAppearanceDetailsAccepted"] = "/attorney/view/case/details/:selectedId?accepted";
    ApplicationRoutes["attorneySubmitResults"] = "/attorney/submit/results";
    ApplicationRoutes["attorneySubmitResultsDetails"] = "/attorney/submit/appearance/result/:selectedId";
    ApplicationRoutes["attorneyMailAccepAppearance"] = "/appearance/accept/:attorneyId/:selectedId/:clientId";
    ApplicationRoutes["attorneyMailRejectAppearance"] = "/appearance/reject/:attorneyId/:appearanceId/:clientId";
    // client specific pages
    ApplicationRoutes["clientAccount"] = "/account";
    ApplicationRoutes["clientSignup"] = "/signup";
    ApplicationRoutes["clientDashboard"] = "/client/dashboard";
    ApplicationRoutes["clientSubmittedAppearance"] = "/client/submitted/appearance";
    ApplicationRoutes["clientManageAppearance"] = "/client/manage/appearance";
    ApplicationRoutes["clientRequestAppearance"] = "/client/request/appearance";
    ApplicationRoutes["clientCreateAppearanceFromTemplate"] = "/client/request/appearance_from_template/:selectedId";
    ApplicationRoutes["clientViewPayments"] = "/client/view/payments";
    ApplicationRoutes["clientViewInvoice"] = "/client/view/invoice/:selectedId";
    ApplicationRoutes["clientViewAppearanceResultDetails"] = "/client/view/case/details/:selectedId";
    ApplicationRoutes["clientCreateTemplateFromAppearance"] = "/client/request/template_from_appearance/:selectedId";
    ApplicationRoutes["clientCreateTemplate"] = "/client/request/template_from_appearance";
    ApplicationRoutes["clientViewTemplateDetails"] = "/client/view/template/details/:selectedId";
    ApplicationRoutes["clientManageTemplate"] = "/client/manage/template";
    ApplicationRoutes["clientPrintInvoice"] = "/print_invoice/:selectedId";
    ApplicationRoutes["clientPdfInvoice"] = "/client/invoice/pdf/:selectedId";
    ApplicationRoutes["clientEditAppearance"] = "/client/edit/appearance/:selectedId";
    // admin specific pages
    ApplicationRoutes["adminDashboard"] = "/admin";
    ApplicationRoutes["adminManageAppearances"] = "/admin/manage_appearance";
    ApplicationRoutes["adminManageDueAppearances"] = "/admin/manage_payment_pending_appearance";
    ApplicationRoutes["adminManageClientList"] = "/admin/manage_client_list";
    ApplicationRoutes["adminClientDetails"] = "/admin/client/details/:id";
    ApplicationRoutes["adminAttorneyDetails"] = "/admin/attorney/details/:id";
    ApplicationRoutes["adminManageAAList"] = "/admin/manage_attorney_list";
    ApplicationRoutes["adminManageEmailTemplates"] = "/admin/manage_email_templates";
    ApplicationRoutes["adminEditEmailTemplate"] = "/admin/edit_email_template/:id";
    ApplicationRoutes["adminAddEmailTemplate"] = "/admin/add_email_template";
    ApplicationRoutes["adminInvoiceHistory"] = "/admin/invoice_history_list";
    ApplicationRoutes["adminInvoiceDetails"] = "/admin/invoice_history/:selectedId";
    ApplicationRoutes["adminManageState"] = "/admin/manage_state";
    ApplicationRoutes["adminManageCounty"] = "/admin/manage_county";
    ApplicationRoutes["adminManageCourts"] = "/admin/manage_court";
    ApplicationRoutes["adminUploadState"] = "/admin/upload_court";
    ApplicationRoutes["adminManageStandardFees"] = "/admin/manage_type_of_appearance";
    ApplicationRoutes["adminAddStandardFee"] = "/admin/add_type_of_appearance";
    ApplicationRoutes["adminEditStandardFee"] = "/admin/edit_type_of_appearance/:selectedId";
    ApplicationRoutes["adminNonStandardFees"] = "/admin/manage_fee";
    ApplicationRoutes["adminManageCoupon"] = "/admin/manage_coupon";
    ApplicationRoutes["adminEditCoupon"] = "/admin/edit_coupon/:selectedId";
    ApplicationRoutes["adminAddCoupon"] = "/admin/add_coupon";
    ApplicationRoutes["adminAAPayroll"] = "/admin/manage/payment";
    ApplicationRoutes["adminAAPayrollHistory"] = "/admin/manage/payment/history";
    ApplicationRoutes["adminAccountEmails"] = "/admin/configuration";
    ApplicationRoutes["adminEmailTemplates"] = "/admin/manage_email_templates";
    ApplicationRoutes["adminAccount"] = "/admin/change_pwd";
    ApplicationRoutes["adminBlogCategory"] = "/admin/manage_blog_category";
    ApplicationRoutes["adminBlog"] = "/admin/manage_blog";
    ApplicationRoutes["adminManageInbox"] = "/admin/manage_inbox";
    ApplicationRoutes["adminAddCourt"] = "/admin/add_court";
    ApplicationRoutes["adminEditCourt"] = "/admin/edit_court/:selectedId";
    ApplicationRoutes["adminManageLog"] = "/admin/manage_log";
    ApplicationRoutes["adminAddCounty"] = "/admin/add_county";
    ApplicationRoutes["adminEditCounty"] = "/admin/edit_count/:selectedId";
    ApplicationRoutes["adminEditState"] = "/admin/edit_state/:selectedId";
    ApplicationRoutes["adminAddState"] = "/admin/add_state";
    ApplicationRoutes["adminViewAppearanceDetails"] = "/admin/view/case/:selectedId";
    ApplicationRoutes["adminViewConversationList"] = "/admin/view/conversationlist/:selectedId";
    // other
    ApplicationRoutes["messages"] = "/user/manage/inbox";
    ApplicationRoutes["events"] = "/user/actions";
    ApplicationRoutes["adminNotifications"] = "/admin/manage_inbox";
    ApplicationRoutes["viewMessage"] = "/user/view/message/:selectedId";
    ApplicationRoutes["viewAdminNotification"] = "/admin/view/message/:selectedId";
    ApplicationRoutes["applicationUpdate"] = "/application_update";
})(ApplicationRoutes || (ApplicationRoutes = {}));
