import { UserRole } from 'redux/auth/constants';
import { createReducer } from 'typesafe-actions';
import { deauthoriseAction, enableDebugAdmin, loginRequestAction, logoutAction, otherTabLogin, otherTabLogout, resetAction, } from './actions';
import { initialState } from './initialState';
// noinspection TypeScriptValidateJSTypes
export const reducer = createReducer(initialState)
    .handleAction(loginRequestAction.request, (state) => (Object.assign(Object.assign({}, state), { isLoading: true })))
    .handleAction(logoutAction, (state) => (Object.assign(Object.assign({}, state), { isLoggedIn: false, isLoading: false, roles: [], error: undefined, expiresAt: 0, token: '' })))
    .handleAction(deauthoriseAction, (state) => (Object.assign(Object.assign({}, state), { isLoggedIn: false, isLoading: false, roles: [], error: undefined, expiresAt: 0, token: '' })))
    .handleAction(otherTabLogout, (state) => (Object.assign(Object.assign({}, state), { isLoggedIn: false, isLoading: false, roles: [], error: undefined, expiresAt: 0, token: '' })))
    .handleAction(loginRequestAction.failure, (state, action) => (Object.assign(Object.assign({}, state), { isLoading: false, error: action.payload })))
    .handleAction(resetAction, (state) => (Object.assign(Object.assign({}, state), { isLoading: false, error: undefined })))
    .handleAction(enableDebugAdmin, (state) => (Object.assign(Object.assign({}, state), { roles: [UserRole.ADMIN] })))
    .handleAction(loginRequestAction.success, (state, action) => (Object.assign(Object.assign({}, state), { isLoggedIn: true, isLoading: false, roles: action.payload.roles, error: undefined, expiresAt: action.payload.expiresAt, token: action.payload.token, fullName: action.payload.fullName })))
    .handleAction(otherTabLogin, (state, action) => (Object.assign(Object.assign({}, state), { isLoggedIn: true, isLoading: false, roles: action.payload.roles, error: undefined, expiresAt: action.payload.expiresAt, token: action.payload.token, fullName: action.payload.fullName })));
