import { useFormikContext } from 'formik';
import * as React from 'react';
export const useFocusOnError = ({ name, fieldRef }) => {
    const formik = useFormikContext();
    React.useEffect(() => {
        if (!fieldRef.current || formik.isValid || !formik.isSubmitting)
            return;
        const hasError = !!formik.errors[name];
        // Get Y of input that is focused now
        const currentY = (document.activeElement && ['INPUT', 'TEXTAREA', 'SELECT'].includes(document.activeElement.tagName.toUpperCase()))
            ? document.activeElement.getBoundingClientRect().top : null;
        const thisElY = fieldRef.current.getBoundingClientRect().top;
        // If our input is higher than currently focused
        const isHigherThanCurrent = currentY === null || thisElY < currentY;
        if (hasError) {
            // console.log(name, isHigherThanCurrent, hasError, thisElY, currentY, fieldRef.current);
        }
        // If our input has error AND is higher than currently focused one, focus on this one.
        if (hasError && isHigherThanCurrent) {
            fieldRef.current.focus();
            // Fix for: Firefox scroll places input on top of the screen on focus
            if (navigator.userAgent.indexOf('Firefox') !== -1) {
                fieldRef.current.scrollIntoView({
                    block: 'center',
                });
            }
        }
    }, [fieldRef.current, formik.isSubmitting, formik.isValid, formik.errors]);
};
