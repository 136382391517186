import { FieldInput } from 'components/Forms/FieldInput';
import { FieldText } from 'components/Forms/FieldText';
import { Success } from 'components/Success';
import { Unsuccess } from 'components/Unsuccess';
import { contactUsAction, resetAction } from 'containers/Contact/duck/actions';
import { reducer } from 'containers/Contact/duck/reducer';
import { saga } from 'containers/Contact/duck/saga';
import { selectContactFormError, selectContactFormLoading, selectContactFormResponse, } from 'containers/Contact/duck/selectors';
import { withContactForm } from 'containers/Contact/withContactForm';
import { useFormikContext } from 'formik';
import { useServerValidate } from 'hooks/useServerValidate';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SagaMode } from 'redux/utils/constants';
import injectReducer from 'redux/utils/injectReducer';
import injectSaga from 'redux/utils/injectSaga';
import './ContactUs.css';
export const InnerForm = (props) => {
    const { handleSubmit, isSubmitting, setSubmitting, setErrors, setStatus, values, status, } = props;
    const serverError = useSelector(selectContactFormError);
    const serverWaiting = useSelector(selectContactFormLoading);
    const serverResult = useSelector(selectContactFormResponse);
    useServerValidate(serverWaiting, serverError, isSubmitting, setErrors, setStatus, setSubmitting);
    const { resetForm } = useFormikContext();
    React.useEffect(() => {
        if (!serverWaiting && serverResult.result) {
            resetForm({
                values: {
                    username: '',
                    lastName: '',
                    subject: '',
                    message: '',
                    mail: '',
                    recaptcha: values.recaptcha,
                },
            });
        }
    }, [serverWaiting, serverResult]);
    return (React.createElement(React.Fragment, null,
        !serverWaiting && serverResult.result && (React.createElement(Success, { message: serverResult.message || 'Your information has successfully been submitted to Attorneys in Motion' })),
        status && React.createElement(Unsuccess, { message: status }),
        React.createElement("form", { onSubmit: handleSubmit },
            React.createElement(FieldInput, { name: "username", required: true, className: "_", inputClass: " ", placeholder: "Name *", autoComplete: "given-name" }),
            React.createElement(FieldInput, { name: "mail", type: "email", required: true, className: "_", inputClass: "input-box", placeholder: "Email *", autoComplete: "email" }),
            React.createElement(FieldInput, { name: "subject", required: true, className: "_", inputClass: " ", placeholder: "Subject *", autoComplete: "subject" }),
            React.createElement(FieldText, { name: "message", required: true, className: "_", inputClass: "LoNotSensitive", placeholder: "Message *", autoComplete: "message" }),
            React.createElement("input", { className: "send-btn", type: "submit", value: "Submit" }))));
};
const Form = withContactForm(false)(InnerForm);
const ContactUs = () => {
    const dispatch = useDispatch();
    const submit = (values) => dispatch(contactUsAction.request(values));
    React.useEffect(() => () => {
        dispatch(resetAction()); // on unmount reset success message
    }, []);
    return (React.createElement("div", { className: "col-lg-4 col-md-4" },
        React.createElement("div", { className: "our-cont" },
            React.createElement("div", { className: "cont-heading" },
                React.createElement("h4", null, "Contact Us"),
                React.createElement("p", null, "We\u2019d love to hear from you. Send us your questions, comments and feedback.")),
            React.createElement("div", { className: "contact-us-box" },
                React.createElement("div", { className: "controls" },
                    React.createElement(Form, { submit: submit }))))));
};
const withSaga = injectSaga({ key: 'contactForm', saga, mode: SagaMode.DAEMON });
const withReducer = injectReducer({ key: 'contactForm', reducer });
export default withReducer(withSaga(ContactUs));
