var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classnames from 'classnames';
import { inputName } from 'components/Forms/inputName';
import { useField, useFormikContext } from 'formik';
import * as React from 'react';
/* eslint-disable react/jsx-props-no-spreading */
export function RawInput(_a) {
    var { name, required, type, inputId, fast, className, index, filter, prefix, forwardedRef, sensitive } = _a, otherProps = __rest(_a, ["name", "required", "type", "inputId", "fast", "className", "index", "filter", "prefix", "forwardedRef", "sensitive"]);
    const strName = inputName(name, prefix, index);
    const [field, meta] = useField(strName);
    const { setFieldValue } = useFormikContext();
    const { onChange } = field;
    const classNameCalc = classnames(className || 'input-bl', {
        error_border: meta.error && meta.touched,
        LoNotSensitive: !sensitive,
    });
    return React.useMemo(() => {
        const filteredOnChange = (e) => {
            if (filter) {
                setFieldValue(strName, filter(e.currentTarget.value), false);
            }
            else {
                onChange(e);
            }
        };
        return (React.createElement("input", Object.assign({ className: classNameCalc }, field, { onChange: filteredOnChange, type: type || 'text', 
            // required={required}
            id: inputId, ref: forwardedRef }, otherProps)));
    }, [
        setFieldValue,
        strName,
        classNameCalc,
        inputId,
        required,
        type,
        fast ? field.value : field,
        fast ? '' : otherProps,
    ]);
}
