/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */
import Fade from 'components/Fade';
import { isMobileApp } from 'constants/isMobileApp';
import React, { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';
import './styles.css';
const ScrollToTop = () => {
    const [visible, setVisible] = useState(false);
    const handleClick = () => {
        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    const handleScroll = throttle(() => {
        if (window.scrollY < 100) {
            setVisible(false);
        }
        else if (!visible) {
            setVisible(!isMobileApp);
        }
    }, 300);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (React.createElement(Fade, { className: "scroll-top-wrapper", show: visible, delay: 300 },
        React.createElement("a", { className: "scroll-top", "aria-label": "Scroll Page To Top", role: "button", onClick: handleClick, tabIndex: -1 })));
};
export default ScrollToTop;
