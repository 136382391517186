/* eslint-disable lines-between-class-members,no-underscore-dangle,block-scoped-var,no-plusplus */
import { cancelFrame, requestFrame, time } from 'components/Carousel3d/utils';
import Showcase from './Showcase';
class Carousel {
    constructor(items, element, options) {
        this.play = () => {
            if (this.timer === 0)
                this.scheduleNextFrame();
        };
        this.rotateItem = (itemIndex, rotation) => {
            const item = this.items[itemIndex];
            const sin = Math.sin(rotation);
            const { farScale } = this;
            const scale = farScale + ((1 - farScale) * (sin + 1) * 0.5);
            item.moveTo(this.xOrigin + (scale * ((Math.cos(rotation) * this.xRadius) - (item.fullWidth * 0.5))), this.yOrigin + (scale * sin * this.yRadius), scale);
        };
        this.go = (count) => {
            this.destRotation += ((2 * Math.PI) / this.items.length) * count;
            this.play();
        };
        this.scheduleNextFrame = () => {
            this.lastTime = time();
            this.timer = this.smooth && cancelFrame
                ? requestFrame(this.playFrame)
                : setTimeout(this.playFrame, 1000 / this.fps);
        };
        this.pause = () => {
            if (this.smooth && cancelFrame) {
                cancelFrame(this.timer);
            }
            else {
                clearTimeout(this.timer);
            }
            this.timer = 0;
        };
        this.playFrame = () => {
            const rem = this.destRotation - this.rotation;
            const now = time();
            const dt = (now - this.lastTime) * 0.002;
            this.lastTime = now;
            if (Math.abs(rem) < 0.003) {
                this.rotation = this.destRotation;
                this.pause();
            }
            else {
                // Rotate asymptotically closer to the destination
                this.rotation = this.destRotation - rem / (1 + (this.speed * dt));
                this.scheduleNextFrame();
            }
            this.render();
        };
        this.enableAutoPlay = () => {
            // Stop auto-play on mouse over
            this.$container.addEventListener('mouseover', () => {
                clearInterval(this.autoPlayTimer);
            });
            // Resume auto-play when mouse leaves the container
            this.$container.addEventListener('mouseout', () => {
                this.autoPlay();
            });
            this.autoPlay();
        };
        this.bindControls = () => {
            if (this._options.buttonLeft) {
                this._options.buttonLeft.addEventListener('click', () => {
                    this.go(-1);
                    return false;
                });
            }
            if (this._options.buttonRight) {
                this._options.buttonRight.addEventListener('click', () => {
                    this.go(1);
                    return false;
                });
            }
            if (this._options.mouseWheel) {
                this.$container.addEventListener('mousewheel', (event, delta) => {
                    this.go((delta > 0) ? 1 : -1);
                    return false;
                });
            }
        };
        this.autoPlay = () => {
            this.autoPlayTimer = setInterval(() => { this.go(this.autoPlayAmount); }, this.autoPlayDelay);
        };
        this.render = () => {
            const count = this.items.length;
            const spacing = (2 * Math.PI) / count;
            let radians = this.rotation;
            for (let i = 0; i < count; i++) {
                this.rotateItem(i, radians);
                radians += spacing;
            }
            if (typeof this.onRendered === 'function')
                this.onRendered(this);
        };
        this.finishInit = () => {
            //
            // Wait until all images have completely loaded
            //
            for (let i = 0; i < this.refs.length; i++) {
                const ref = this.refs[i];
                if ((ref.nodeName === 'IMG')
                    && ((ref.width === undefined) || ((ref.complete !== undefined) && !ref.complete)))
                    return;
            }
            clearInterval(this.initTimer);
            // Init items
            for (let i = 0; i < this.refs.length; i++) {
                this.items.push(new Showcase(this.refs[i], this.itemOptions));
            }
            // Disable click-dragging of items
            this.$container.addEventListener('mousedown onselectstart', () => false);
            if (this.autoPlayAmount !== 0)
                this.enableAutoPlay();
            this.bindControls();
            this.render();
            if (typeof this.onLoaded === 'function')
                this.onLoaded(this);
        };
        const self = this;
        this.items = [];
        this.refs = items;
        this.xOrigin = (options.xOrigin === null) ? element.clientWidth * 0.5 : options.xOrigin;
        this.yOrigin = (options.yOrigin === null) ? element.clientHeight * 0.1 : options.yOrigin;
        this.xRadius = (options.xRadius === null) ? element.clientWidth / 2.3 : options.xRadius;
        this.yRadius = (options.yRadius === null) ? element.clientHeight / 6 : options.yRadius;
        this.farScale = options.farScale;
        this.rotation = Math.PI / 2; // start with the first item positioned in front
        this.destRotation = Math.PI / 2;
        this.speed = options.speed;
        this.smooth = options.smooth;
        this.fps = options.fps;
        this.timer = 0;
        this.autoPlayAmount = options.autoPlay;
        this.autoPlayDelay = options.autoPlayDelay;
        this.autoPlayTimer = 0;
        this.onLoaded = options.onLoaded;
        this.onRendered = options.onRendered;
        this.$container = element;
        this.itemOptions = {
            transforms: options.transforms,
        };
        if (options.mirror) {
            this.itemOptions.mirror = Object.assign({ gap: 2 }, options.mirror);
        }
        // eslint-disable-next-line no-param-reassign
        element.style.overflow = 'hidden';
        this.initTimer = setInterval(() => { self.finishInit(); }, 50);
        this._options = options;
    }
}
export default Carousel;
