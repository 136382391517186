import { clearAuthStorage, clearInMemoryAuthStorage, getAuthorization, storeAuthorizationLocally, } from 'client/authStorage';
import { axiosInstance } from 'client/axiosInstance';
import { API_PATHES, AUTH_VARIABLE } from 'client/constants';
import { push } from 'connected-react-router';
import { ApplicationRoutes } from 'containers/System/routes';
import { Logger } from 'logger';
import { all, call, delay, put, select, spawn, takeEvery, } from 'redux-saga/effects';
import { userDataToLog } from 'redux/analytics/calls';
import { gtmAALogin, gtmClientLogin } from 'redux/analytics/gtm';
import { UserRole } from 'redux/auth/constants';
import { selectIsLoggedIn } from 'redux/auth/selectors';
import { startNotifications, stopNotifications } from 'redux/notifications/saga';
import { getStoreInstance } from 'redux/storeInstance';
import { getType } from 'typesafe-actions';
import { extractError } from 'utils/extractError';
import { isDebug } from 'utils/isDebug';
import { afterAuthIsOk, afterLogoutAction, deauthoriseAction, loginRequestAction, logoutAction, otherTabLogin, otherTabLogout, refreshTokenAction, } from './actions';
export const REFRESH_IN_MINUTES = 5;
const isUnicorn = () => {
    try {
        return !!localStorage.getItem('unicorn');
    }
    catch (e) {
        return false;
    }
};
export const parseRoles = (roles) => roles.map((r) => r.role);
function* scheduleRefreshToken(at) {
    if (getAuthorization()) {
        const now = Date.now();
        const fireIn = Math.min(2147483647, Math.max(1000, at - now - REFRESH_IN_MINUTES * 60000));
        Logger.debug('Scheduled token refresh in', { seconds: fireIn / 1000, initial: (at - now) / 1000 });
        yield delay(fireIn);
        yield put(refreshTokenAction.request());
    }
}
export function* afterLogin(action) {
    yield spawn(scheduleRefreshToken, action.payload.expiresAt);
    yield false;
}
export function* afterLogout() {
    clearInMemoryAuthStorage();
    yield false;
}
export function* onSuccess(response, redirect, redirectPath) {
    if (isUnicorn()) {
        response.roles = [UserRole.ADMIN];
    }
    yield put(loginRequestAction.success(response));
    if (response.roles.findIndex((u) => u === UserRole.ATTORNEY) >= 0) {
        yield call(gtmAALogin);
    }
    else if (response.roles.findIndex((u) => u === UserRole.CLIENT) >= 0) {
        yield call(gtmClientLogin);
    }
    const redirectTo = window.location.search.startsWith('?returnTo=') ? window.location.search.substr(10) : null;
    if (redirect) {
        if (redirectPath || redirectTo) {
            yield put(push(redirectPath || redirectTo));
        }
        else if (response.roles.findIndex((u) => u === UserRole.ATTORNEY) >= 0) {
            yield put(push(ApplicationRoutes.attorneyDashboard));
        }
        else if (response.roles.findIndex((u) => u === UserRole.CLIENT) >= 0) {
            yield put(push(ApplicationRoutes.clientDashboard));
        }
        else if (response.roles.findIndex((u) => u === UserRole.ADMIN) >= 0) {
            yield put(push(ApplicationRoutes.adminDashboard));
        }
        else {
            yield put(push(ApplicationRoutes.root));
        }
    }
    storeAuthorizationLocally(response);
    yield spawn(scheduleRefreshToken, response.expiresAt);
    yield call(startNotifications);
    yield call(userDataToLog, { name: response.fullName, email: response.email });
    yield put(afterAuthIsOk(response));
}
function* loginSaga(action) {
    try {
        const axios = axiosInstance();
        //Logger.debug('Logging in with', action.payload);
        const raw = yield call(axios.post, API_PATHES.auth.login, {
            login: action.payload.login,
            password: action.payload.password,
            rememberMe: action.payload.rememberMe,
            recapcha: isDebug ? (action.payload.recapcha || 'fake') : action.payload.recapcha,
        });
        const response = {
            fullName: raw.data.fullName,
            token: raw.data.token,
            email: raw.data.email,
            expiresAt: raw.data.expiresAt ? ((new Date(raw.data.expiresAt)).getTime()) : (Date.now() + REFRESH_IN_MINUTES * 2 * 60000),
            roles: parseRoles(raw.data.roles),
        };
        yield call(onSuccess, response, true, action.payload.redirectTo || '');
    }
    catch (err) {
        const error = extractError(err, 'Login failed');
        Logger.error('Login failed', { error });
        yield put(loginRequestAction.failure(error));
    }
}
function* deauthoriseSaga() {
    try {
        const axios = axiosInstance();
        yield call(stopNotifications);
        clearAuthStorage();
        yield call(axios.post, API_PATHES.auth.logout, {});
        yield put(afterLogoutAction());
    }
    catch (err) {
        const error = extractError(err, 'Logout failed');
        Logger.error('Logout failed', { error });
    }
}
function* logoutSaga() {
    try {
        yield put(push(ApplicationRoutes.root));
        yield call(deauthoriseSaga);
    }
    catch (err) {
        const error = extractError(err, 'Logout failed');
        Logger.error('Logout failed', { error });
    }
}
function* refreshSaga() {
    try {
        const axios = axiosInstance();
        Logger.debug('Refreshing token');
        const raw = yield call(axios.post, API_PATHES.auth.refresh, {});
        const response = {
            fullName: raw.data.fullName,
            token: raw.data.token,
            email: raw.data.email,
            expiresAt: raw.data.expiresAt ? (new Date(raw.data.expiresAt)).getTime() : Date.now() + REFRESH_IN_MINUTES * 2 * 60000,
            roles: parseRoles(raw.data.roles),
        };
        yield call(onSuccess, response, false);
    }
    catch (err) {
        const error = extractError(err, 'Refresh failed');
        Logger.error('Refresh failed', { error });
        yield put(logoutAction());
    }
}
function* checkSaga() {
    try {
        const axios = axiosInstance();
        Logger.debug('Checking token');
        const raw = yield call(axios.post, API_PATHES.auth.check, {});
        const response = {
            fullName: raw.data.fullName,
            token: raw.data.token,
            email: raw.data.email,
            expiresAt: raw.data.expiresAt ? (new Date(raw.data.expiresAt)).getTime() : Date.now() + REFRESH_IN_MINUTES * 2 * 60000,
            roles: parseRoles(raw.data.roles),
        };
        yield call(onSuccess, response, false);
    }
    catch (err) {
        const error = extractError(err, 'Check token failed');
        Logger.error('Check token failed', { error });
        yield put(logoutAction());
    }
}
export function* monitorAuth() {
    window.addEventListener('storage', (message) => {
        if (message.key === AUTH_VARIABLE) {
            const store = getStoreInstance();
            if (store) {
                if (message.oldValue === null && message.newValue) {
                    store.dispatch(otherTabLogin(JSON.parse(message.newValue)));
                    return;
                }
                if (message.newValue === null) {
                    store.dispatch(otherTabLogout());
                }
            }
        }
    });
    yield false;
}
export function* saga() {
    const isLoggedIn = yield select(selectIsLoggedIn);
    if (isLoggedIn) {
        yield call(checkSaga);
    }
    else {
        const auth = getAuthorization();
        if (auth) {
            // Seems we have this page cached as non logged in, force a check to log in
            yield call(checkSaga);
        }
    }
    yield spawn(monitorAuth);
    yield all([
        yield takeEvery(getType(loginRequestAction.request), loginSaga),
        yield takeEvery(getType(refreshTokenAction.request), refreshSaga),
        yield takeEvery(getType(logoutAction), logoutSaga),
        yield takeEvery(getType(deauthoriseAction), deauthoriseSaga),
        yield takeEvery(getType(otherTabLogin), afterLogin),
        yield takeEvery(getType(otherTabLogout), afterLogout),
    ]);
}
