import { CheckRole } from 'components/CheckRole';
import { actionsBraces } from 'components/Header/formatActions';
import { ApplicationRoutes } from 'containers/System/routes';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutAction } from 'redux/auth/actions';
import { UserRole } from 'redux/auth/constants';
import newProductIcon from '../../../assets/images/new-product-icon.png';
const Spacer = () => (React.createElement(React.Fragment, null,
    React.createElement("li", { className: "mr-4" }, "\u00A0"),
    React.createElement("li", { className: "mr-4" }, "\u00A0"),
    React.createElement("li", { className: "mr-4" }, "\u00A0")));
const NavigationLink = ({ route, children }) => (React.createElement("li", null,
    React.createElement(Link, { to: route }, children)));
const DropdownMenu = ({ role, dashboardRoute, items, }) => (React.createElement(CheckRole, { role: role, fallback: null },
    React.createElement("li", { className: "input-btn my-account my_account_desk" },
        React.createElement(Link, { to: dashboardRoute },
            React.createElement("span", null, "My Account"))),
    React.createElement(Dropdown, { as: "li", className: "input-btn my_account_tab" },
        React.createElement(Dropdown.Toggle, { as: "a", role: "button", id: "toggle-4" },
            React.createElement("span", null, "My Account")),
        React.createElement(Dropdown.Menu, { className: "dropdown-menu" },
            React.createElement("div", { className: "arrow-up" }),
            items.map((item, index) => (React.createElement(Dropdown.Item, { as: "li", key: index },
                React.createElement(Link, { to: item.route }, item.label))))))));
const PrivateMenu = ({ unreadEvents }) => {
    const dispatch = useDispatch();
    const logout = React.useCallback(() => dispatch(logoutAction()), []);
    const clientItems = [
        { route: ApplicationRoutes.clientDashboard, label: 'My Dashboard' },
        { route: ApplicationRoutes.events, label: actionsBraces(unreadEvents) },
        {
            route: ApplicationRoutes.clientRequestAppearance,
            label: 'Schedule Appearance',
        },
        {
            route: ApplicationRoutes.clientManageAppearance,
            label: 'Manage My Appearances',
        },
        {
            route: ApplicationRoutes.clientManageTemplate,
            label: 'Manage Templates',
        },
        {
            route: ApplicationRoutes.clientSubmittedAppearance,
            label: 'Appearance Reports',
        },
        { route: ApplicationRoutes.messages, label: 'Messages' },
        {
            route: ApplicationRoutes.clientViewPayments,
            label: 'Appearance Receipts and Invoices',
        },
        { route: ApplicationRoutes.clientAccount, label: 'Manage My Account' },
    ];
    const attorneyItems = [
        { route: ApplicationRoutes.attorneyDashboard, label: 'My Dashboard' },
        { route: ApplicationRoutes.events, label: actionsBraces(unreadEvents) },
        {
            route: ApplicationRoutes.attorneyAvailableAppearance,
            label: 'View Available Appearances',
        },
        {
            route: ApplicationRoutes.attorneyManageAppearance,
            label: 'Manage My Appearances',
        },
        {
            route: ApplicationRoutes.attorneySubmitResults,
            label: 'Submit Appearance Results',
        },
        { route: ApplicationRoutes.messages, label: 'Messages' },
        { route: ApplicationRoutes.attorneyAccount, label: 'Manage My Account' },
    ];
    return (React.createElement("div", { className: "navbar navbar-custom", role: "navigation" },
        React.createElement("div", { className: "navbar-collapse collapse" },
            React.createElement("ul", { className: "nav navbar-nav" },
                React.createElement(CheckRole, { role: [UserRole.CLIENT, UserRole.ATTORNEY], fallback: null },
                    React.createElement(CheckRole, { role: [UserRole.ATTORNEY], fallback: null },
                        React.createElement(Spacer, null)),
                    React.createElement(NavigationLink, { route: ApplicationRoutes.aboutUs }, "About Us"),
                    React.createElement(NavigationLink, { route: ApplicationRoutes.services }, "Services"),
                    React.createElement(NavigationLink, { route: ApplicationRoutes.courtInformation }, "Court Information"),
                    React.createElement(NavigationLink, { route: ApplicationRoutes.contact }, "Contact"),
                    React.createElement(CheckRole, { role: [UserRole.CLIENT], fallback: null },
                        React.createElement(NavigationLink, { route: ApplicationRoutes.clientRequestAppearance }, "Request an Appearance"))),
                React.createElement(CheckRole, { role: [UserRole.ADMIN, UserRole.ADMIN_LIMITED], fallback: null },
                    React.createElement(Spacer, null),
                    React.createElement(NavigationLink, { route: ApplicationRoutes.events }, actionsBraces(unreadEvents)),
                    React.createElement(NavigationLink, { route: ApplicationRoutes.adminManageLog }, "System Log"),
                    React.createElement(NavigationLink, { route: ApplicationRoutes.adminManageAppearances }, "Appearances"),
                    React.createElement(NavigationLink, { route: ApplicationRoutes.adminManageClientList }, "Clients"),
                    React.createElement(NavigationLink, { route: ApplicationRoutes.adminManageAAList }, "Attorneys")),
                React.createElement(CheckRole, { role: [UserRole.ADMIN], fallback: null },
                    React.createElement(NavigationLink, { route: ApplicationRoutes.adminAAPayroll }, "AA Payroll")),
                React.createElement(DropdownMenu, { role: UserRole.CLIENT, dashboardRoute: ApplicationRoutes.clientDashboard, items: clientItems }),
                React.createElement(DropdownMenu, { role: UserRole.ATTORNEY, dashboardRoute: ApplicationRoutes.attorneyDashboard, items: attorneyItems }),
                React.createElement("li", { className: "input-btn" },
                    React.createElement("a", { role: "button", tabIndex: 0, className: "sign-out-link", onClick: logout },
                        React.createElement("span", null, "Logout"))),
                React.createElement("li", { className: "input-btn dropdown", style: {
                        margin: '3px 8px 8px 549px',
                        height: '26px',
                        paddingTop: '3px',
                        paddingLeft: '19px',
                        paddingRight: '19px',
                    } },
                    React.createElement("a", { href: "https://lawbeez.com", target: "_blank", rel: "noreferrer", style: { fontSize: '11px' } },
                        React.createElement("span", null, "Hire Freelance Attorney"),
                        React.createElement("img", { src: newProductIcon, alt: "Hire Freelance Attorney", title: "Hire Freelance Attorney", style: {
                                position: 'fixed',
                                marginTop: '-5px',
                                marginLeft: '16px',
                            } })))))));
};
export default PrivateMenu;
