export const parseMatches = (str) => {
    let m;
    // eslint-disable-next-line no-useless-escape
    const regex = /^([a-zA-Z]*:?\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/igm;
    const res = [];
    // eslint-disable-next-line no-cond-assign
    while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            // eslint-disable-next-line no-plusplus
            regex.lastIndex++;
        }
        // The result can be accessed through the `m`-variable.
        m.forEach((match) => {
            res.push(match);
        });
    }
    return res;
};
export const getUrlPath = (str) => {
    const matches = parseMatches(str);
    if (matches.length === 3) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [domain, ...rest] = matches;
        return str.substr(domain.length);
    }
    return str;
};
