var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Capacitor } from '@capacitor/core';
import { getAuthorization } from 'client/authStorage';
import { axiosInstance } from 'client/axiosInstance';
import { API_PATHES } from 'client/constants';
import { isMobileApp } from 'constants/isMobileApp';
import { IS_SSR } from 'constants/isssr';
import { Logger } from 'logger';
import { all, select, takeEvery, call, put, } from 'redux-saga/effects';
import { afterAuthIsOk, loginRequestAction } from 'redux/auth/actions';
import { selectIsLoggedIn } from 'redux/auth/selectors';
import { markOneAsRead, refreshUnreadCount, setUnreadCount } from 'redux/notifications/actions';
import { getUnreadNotificationCount, markNotificationRead } from 'redux/notifications/api/calls';
import { getFcmToken, putToken } from 'redux/notifications/lsToken';
import { isWebPushSupported } from 'redux/notifications/web/isSupported';
import { getType } from 'typesafe-actions';
let initStarted = false;
const platform = Capacitor ? Capacitor.platform : 'web';
function unregisterTokenFromCurrentUser(token) {
    return __awaiter(this, void 0, void 0, function* () {
        if (getAuthorization()) {
            Logger.debug('Unregistering FCM token', { token });
            const axios = axiosInstance();
            yield axios.delete(API_PATHES.firebase.token, {
                data: Object.assign({ token, type: platform }, (isMobileApp ? {} : { domain: window.location.host })),
            });
        }
        else {
            Logger.debug('No active user, no need to unregister FCM token', { token });
        }
    });
}
function registerTokenToCurrentUser(token) {
    return __awaiter(this, void 0, void 0, function* () {
        if (getAuthorization()) {
            Logger.debug('Registering FCM token', { token });
            const axios = axiosInstance();
            yield axios.post(API_PATHES.firebase.token, Object.assign({ token, type: platform }, (isMobileApp ? {} : { domain: window.location.host })));
        }
        else {
            Logger.debug('No active user, no need to register FCM token', { token });
        }
    });
}
export function* startNotifications() {
    const currentToken = yield getFcmToken();
    if (currentToken) {
        Logger.debug('User has logged in, need to update his FCM token');
        try {
            yield registerTokenToCurrentUser(currentToken);
        }
        catch (e) {
            Logger.debug('Could not start FCM notifications as token could not been registered', e);
        }
    }
    yield 1;
}
export function* stopNotifications() {
    const currentToken = yield getFcmToken();
    if (currentToken) {
        Logger.debug('User has logged out, need to remove his FCM token');
        try {
            yield unregisterTokenFromCurrentUser(currentToken);
        }
        catch (e) {
            Logger.debug('Could not stop FCM notifications as token could not been unregistered', e);
        }
    }
    yield 1;
}
function updateUserToken(oldToken, newToken) {
    return __awaiter(this, void 0, void 0, function* () {
        if (oldToken && oldToken !== newToken) {
            try {
                yield unregisterTokenFromCurrentUser(oldToken);
            }
            catch (e) {
                Logger.debug('Error happened during unregistering token', e);
            }
        }
        if (newToken) {
            try {
                yield registerTokenToCurrentUser(newToken);
            }
            catch (e) {
                Logger.debug('Error happened during registering token', e);
            }
        }
    });
}
function webUpdateFCMToken(messaging) {
    return __awaiter(this, void 0, void 0, function* () {
        Logger.debug('Refreshing WEB FCM token');
        let token;
        const oldToken = getFcmToken();
        Logger.info('FCM token from local storage', { token: getFcmToken() });
        try {
            token = yield messaging.getToken();
            if (!token) {
                Logger.warn('FCM token couldnt be got, trying again');
                token = yield messaging.getToken();
            }
            if (!token) {
                Logger.error('FCM token couldnt be got for unknown reason');
            }
        }
        catch (e) {
            if (e.code === 'messaging/token-unsubscribe-failed') {
                Logger.debug('https://github.com/firebase/firebase-js-sdk/issues/2364');
                token = yield messaging.getToken();
            }
            else {
                throw e; // Ok fine that's a real error
            }
        }
        putToken(token);
        Logger.info('FCM token', { token, oldToken });
        if (oldToken && oldToken !== token) {
            Logger.warn('FCM token refreshed');
        }
        yield updateUserToken(oldToken, token);
    });
}
function* init() {
    if (initStarted) {
        return false;
    }
    initStarted = true;
    if (IS_SSR) {
        Logger.info('Detected Static Generation, skipping SW');
        return false;
    }
    if (Capacitor && (Capacitor.platform === 'android' || Capacitor.platform === 'ios')) {
        Logger.info('Detected Capacitor environment, starting FCM native plugin', { platform: Capacitor.platform });
        const fcmModule = yield (import(/* webpackChunkName: "capacitor-notifications" */ './capacitor/init'));
        try {
            const messagingRegistrator = yield fcmModule.default;
            const messaging = yield messagingRegistrator();
            Logger.info('Initializing push done, getting token');
            const oldToken = getFcmToken();
            Logger.info('FCM token from local storage', { token: oldToken });
            const data = yield messaging.getToken();
            const newToken = data.token;
            Logger.info('New FCM token', data);
            putToken(newToken);
            Logger.info('FCM token', { newToken, oldToken });
            if (oldToken && oldToken !== newToken) {
                Logger.warn('FCM token refreshed');
            }
            yield updateUserToken(oldToken, newToken);
        }
        catch (e) {
            Logger.error('Could not start messaging', e);
        }
    }
    else if (isWebPushSupported) {
        Logger.info('Detected WEB environment, starting FCM web messaging', { platform: Capacitor.platform });
        const messagingModule = yield (import(/* webpackChunkName: "web-notifications" */ './web/init'));
        try {
            const messaging = yield messagingModule.default;
            yield webUpdateFCMToken(messaging);
            messaging.onTokenRefresh(() => {
                Logger.warn('FCM token needs to be refreshed');
                webUpdateFCMToken(messaging);
            });
        }
        catch (e) {
            Logger.error('Could not start messaging', e);
        }
    }
    else {
        Logger.warn('Detected no compatible messaging environment');
    }
    return false;
}
function* refreshUnreadEvents() {
    const isAuthed = yield select(selectIsLoggedIn);
    if (isAuthed) {
        const amount = yield call(getUnreadNotificationCount);
        yield put(setUnreadCount(amount));
    }
}
function* markAsRead(action) {
    const isAuthed = yield select(selectIsLoggedIn);
    if (isAuthed) {
        const amount = yield call(markNotificationRead, action.payload);
        yield put(setUnreadCount(amount));
    }
}
export function* saga() {
    yield all([
        yield takeEvery(getType(loginRequestAction.success), init),
        yield takeEvery([refreshUnreadCount, afterAuthIsOk], refreshUnreadEvents),
        yield takeEvery(markOneAsRead, markAsRead),
    ]);
}
