import { ContentWrapper } from 'components/ContentWrapper';
import Page from 'components/Page';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
import './styles.css';
import { prerenderedLoadable } from 'utils/prefetch';
const CourtInformationPage = prerenderedLoadable(() => import(/* webpackChunkName: "CourtInformation" */ './CourtInformation'));
export const CourtInformation = () => (React.createElement(Page, { title: "Appearance Attorney Court Information" },
    React.createElement(ContentWrapper, null,
        React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
            React.createElement(CourtInformationPage, null)))));
