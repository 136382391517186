import React from 'react';
import { Link } from 'react-router-dom';
import { ApplicationRoutes } from 'containers/System/routes';
const Menu = () => (React.createElement("ul", { className: "footer-menu" },
    React.createElement("li", null,
        React.createElement(Link, { to: ApplicationRoutes.aboutUs }, "About Us")),
    React.createElement("li", { className: "dash" }, "-"),
    React.createElement("li", null,
        React.createElement(Link, { to: ApplicationRoutes.services }, "Services & Fees")),
    React.createElement("li", { className: "dash" }, "-"),
    React.createElement("li", null,
        React.createElement(Link, { to: ApplicationRoutes.courtInformation }, "Court Information")),
    React.createElement("li", { className: "dash" }, "-"),
    React.createElement("li", null,
        React.createElement(Link, { to: ApplicationRoutes.faq }, "FAQ")),
    React.createElement("li", { className: "dash" }, "-"),
    React.createElement("li", null,
        React.createElement(Link, { to: ApplicationRoutes.contact }, "Contact Us"))));
export default Menu;
