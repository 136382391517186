export const mapSomeErrors = (map) => (errors) => {
    const copy = Object.assign({}, errors);
    const result = {};
    Object.keys(map).forEach((key) => {
        if (copy[key]) {
            result[map[key]] = copy[key];
            delete copy[key];
        }
    });
    return Object.assign(Object.assign({}, result), copy);
};
export const extractError = (err, fallback, mapErrors) => {
    // console.log(err, err.isAxiosError, err.response, err.response.data);
    if (err.isAxiosError) {
        const axiosError = err;
        if (axiosError.response && axiosError.response.data) {
            const { data } = axiosError.response;
            return {
                error: err,
                message: data.message || axiosError.message || fallback,
                errors: mapErrors ? mapErrors(data.errors || {}) : (data.errors || {}),
            };
        }
    }
    return {
        error: err,
        message: err.toString() || fallback,
        errors: {},
    };
};
