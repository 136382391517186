import DesktopMenu from 'components/Header/DesktopMenu';
import MobileMenu from 'components/Header/MobileMenu';
import WelcomeMessage from 'components/Header/WelcomeMessage';
import Logo from 'components/Logo';
import { IS_SSR } from 'constants/isssr';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn, selectUserFullName } from 'redux/auth/selectors';
import { selectUnreadEvents } from 'redux/notifications/selectors';
const Header = () => {
    const authed = useSelector(selectIsLoggedIn);
    const userName = useSelector(selectUserFullName);
    const unreadEvents = useSelector(selectUnreadEvents);
    const [showAuthed, setShowAuthed] = React.useState(false);
    React.useEffect(() => {
        if (!IS_SSR && authed !== showAuthed) {
            setShowAuthed(authed);
        }
    }, [authed, showAuthed]);
    return (React.createElement("div", { className: "header-cont" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-lg-12 header-portion no-pad" },
                    React.createElement("div", { className: "col-lg-3 no-pad" },
                        React.createElement(Logo, null)),
                    showAuthed && React.createElement(WelcomeMessage, { events: unreadEvents, name: userName }),
                    React.createElement("div", { className: "col-lg-9  navigations no-pad-res-ipad" },
                        React.createElement(DesktopMenu, { unreadEvents: unreadEvents })),
                    React.createElement(MobileMenu, { unreadEvents: unreadEvents }))))));
};
export default Header;
