import { Logger } from 'logger';
export const FCM_TOKEN = 'messaging';
let storedToken;
export const getFcmToken = () => {
    if (storedToken) {
        return storedToken;
    }
    try {
        const tokenFromLs = localStorage.getItem(FCM_TOKEN);
        if (tokenFromLs && tokenFromLs !== 'null' && tokenFromLs !== null) {
            storedToken = tokenFromLs;
            return tokenFromLs;
        }
    }
    catch (e) {
        Logger.debug('Local storage not available for reading FCM token');
    }
    return undefined;
};
export const putToken = (token) => {
    if (token) {
        storedToken = token;
        try {
            localStorage.setItem(FCM_TOKEN, token);
        }
        catch (e) {
            Logger.debug('Local storage not available for storing FCM token');
        }
    }
    else {
        storedToken = undefined;
        try {
            localStorage.removeItem(FCM_TOKEN);
        }
        catch (e) {
            Logger.debug('Local storage not available for deleting FCM token');
        }
    }
};
export const hasFcmToken = () => !!getFcmToken();
