import { SagaMode } from './constants';
export function injectSagaFactory(store) {
    return function injectSaga(key, descriptor = {}, ...args) {
        const newDescriptor = Object.assign(Object.assign({}, descriptor), { mode: descriptor.mode || SagaMode.RESTART_ON_REMOUNT });
        const { saga, mode } = newDescriptor;
        let hasSaga = Reflect.has(store.injectedSagas, key);
        if (process.env.NODE_ENV !== 'production') {
            const oldDescriptor = store.injectedSagas[key];
            // enable hot reloading of daemon and once-till-unmount sagas
            if (hasSaga && oldDescriptor.saga !== saga && oldDescriptor.task) {
                oldDescriptor.task.cancel();
                hasSaga = false;
            }
        }
        if (!hasSaga || (hasSaga && mode !== SagaMode.DAEMON && mode !== SagaMode.ONCE_TILL_UNMOUNT)) {
            store.injectedSagas[key] = Object.assign(Object.assign({}, newDescriptor), { task: store.runSaga(saga, ...args) }); // eslint-disable-line no-param-reassign
        }
    };
}
export function ejectSagaFactory(store) {
    return function ejectSaga(key) {
        if (Reflect.has(store.injectedSagas, key)) {
            const descriptor = store.injectedSagas[key];
            if (descriptor.mode !== SagaMode.DAEMON) {
                if (descriptor.task) {
                    descriptor.task.cancel();
                }
                // Clean up in production; in development we need `descriptor.saga` for hot reloading
                if (process.env.NODE_ENV === 'production') {
                    // Need some value to be able to detect `ONCE_TILL_UNMOUNT` sagas in `injectSaga` so we keep key but not sagas
                    descriptor.task = undefined; // eslint-disable-line no-param-reassign
                    descriptor.saga = undefined; // eslint-disable-line no-param-reassign
                }
            }
        }
    };
}
export default function getInjectors(store) {
    return {
        injectSaga: injectSagaFactory(store),
        ejectSaga: ejectSagaFactory(store),
    };
}
