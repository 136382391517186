import { ApplicationRoutes } from 'containers/System/routes';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { logoutAction } from 'redux/auth/actions';
export const Logout = () => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(logoutAction());
    }, []);
    return React.createElement(Redirect, { to: ApplicationRoutes.root });
};
