import React from 'react';
import postIcon from './images/post-icon.png';
import notifyIcon from './images/notify-icon.png';
import coverIcon from './images/cover-icon.png';
import reportIcon from './images/report-icon.png';
const LawyerBenefits = () => (React.createElement("div", { className: "container-fluid no-pad" },
    React.createElement("div", { className: "lawyer-bg" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "col-lg-12 lawyer-portion no-pad" },
                React.createElement("p", { className: "law-head" }, "Order your appearance coverage 24 hours a day 365 days a year, from any device."),
                React.createElement("div", { className: "col-lg-3 col-md-3 col-sm-6 law-box" },
                    React.createElement("div", { className: "img-top" },
                        React.createElement("img", { src: postIcon, alt: "Request Appearance Coverage", title: "Request Appearance Coverage" })),
                    React.createElement("div", { className: "lawy-text" },
                        React.createElement("h4", null, "Request Appearance Coverage"),
                        React.createElement("p", null, "Enter your appearance information and upload documents."))),
                React.createElement("div", { className: "col-lg-3 col-md-3 col-sm-6 law-box" },
                    React.createElement("div", { className: "img-top" },
                        React.createElement("img", { src: notifyIcon, alt: "Coverage Attorneys", title: "Coverage Attorneys" })),
                    React.createElement("div", { className: "lawy-text" },
                        React.createElement("h4", null, "Coverage"),
                        React.createElement("p", null, "AIM assigns an experienced attorney, qualified in your area of practice."))),
                React.createElement("div", { className: "col-lg-3 col-md-3 col-sm-6 law-box" },
                    React.createElement("div", { className: "img-top" },
                        React.createElement("img", { src: coverIcon, alt: "Special Appearance Attorneys", title: "Special Appearance Attorneys" })),
                    React.createElement("div", { className: "lawy-text" },
                        React.createElement("h4", null, "Appearance"),
                        React.createElement("p", null, "One of our qualified attorneys stands in and appears on your behalf."))),
                React.createElement("div", { className: "col-lg-3 col-md-3 col-sm-6 law-box" },
                    React.createElement("div", { className: "img-top" },
                        React.createElement("img", { src: reportIcon, alt: "Appearance Reports", title: "Appearance Reports" })),
                    React.createElement("div", { className: "lawy-text" },
                        React.createElement("h4", null, "Appearance Reports"),
                        React.createElement("p", null, "Receive notification within 24 hours of your appearance."))))))));
export default LawyerBenefits;
