import { axiosInstance } from 'client/axiosInstance';
import { API_PATHES } from 'client/constants';
import { push } from 'connected-react-router';
import { isMobileApp } from 'constants/isMobileApp';
import { ApplicationRoutes } from 'containers/System/routes';
import { all, call, spawn, take, put, select, takeEvery, } from 'redux-saga/effects';
import { afterAuthIsOk } from 'redux/auth/actions';
import { selectIsLoggedIn, selectLocation, selectUserIsAA, selectUserIsClient, } from 'redux/auth/selectors';
import { appResumed } from 'redux/notifications/actions';
import { eventChannel } from 'redux-saga';
import { Plugins } from '@capacitor/core';
import { saga as authSaga } from './auth/saga';
import { saga as notificationsSaga } from './notifications/saga';
const { App, Device } = Plugins;
export function* checkMobileVersion() {
    if (isMobileApp) {
        const axios = axiosInstance();
        try {
            const response = yield call(axios.get, API_PATHES.mobile);
            const { version } = response.data;
            const { appBuild } = yield call(Device.getInfo);
            if (version > parseInt(appBuild, 10)) {
                yield put(push(ApplicationRoutes.applicationUpdate));
            }
        }
        catch (e) {
            // console.error(e)
        }
    }
}
export function reloadListener() {
    return eventChannel((emitter) => {
        const listener = (state) => {
            if (state.isActive) {
                emitter(state);
            }
        };
        if (App) {
            App.addListener('appStateChange', listener);
            // window.addEventListener('focus', listener);
        }
        return () => {
            if (App) {
                // App.removeListener('appStateChange', listener);
                // window.removeEventListener('focus', listener);
            }
        };
    });
}
export function* watchReload() {
    const chan = yield call(reloadListener);
    try {
        while (true) {
            yield take(chan);
            yield put(appResumed(Date.now()));
        }
        // eslint-disable-next-line no-empty
    }
    finally {
    }
}
export function* redirectToDashboard() {
    const isAuthed = yield select(selectIsLoggedIn);
    const location = yield select(selectLocation);
    const { pathname } = location;
    if (pathname === ApplicationRoutes.root && isAuthed && isMobileApp) {
        const isAA = yield select(selectUserIsAA);
        const isClient = yield select(selectUserIsClient);
        if (isAA) {
            yield put(push(ApplicationRoutes.attorneyDashboard));
        }
        else if (isClient) {
            yield put(push(ApplicationRoutes.clientDashboard));
        }
        else {
            yield put(push(ApplicationRoutes.adminDashboard));
        }
    }
}
export function* watchMobileOpened() {
    yield call(redirectToDashboard);
    yield takeEvery([appResumed, afterAuthIsOk], redirectToDashboard);
}
export function* watchAppVersion() {
    yield call(checkMobileVersion);
    yield takeEvery([appResumed, afterAuthIsOk], checkMobileVersion);
}
export function* rootSaga() {
    yield spawn(watchReload);
    yield spawn(watchMobileOpened);
    yield spawn(watchAppVersion);
    yield all([
        authSaga(),
        notificationsSaga(),
    ]);
}
