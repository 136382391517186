var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ErrorText } from 'components/Forms/ErrorText';
import { inputName } from 'components/Forms/inputName';
import { Label } from 'components/Forms/Label';
import { useFocusOnError } from 'hooks/useFocusOnError';
import * as React from 'react';
import { uniqueId } from 'utils/uniqueId';
import { ErrorMessage, useField } from 'formik';
import classnames from 'classnames';
/*
For simple fields use as FieldInput<keyof SomeObject> name='fieldName'
For array fields use as FieldInput<keyof SomeObject> name='fieldName' index={indexValue}
For nested fields use as FieldInput<keyof NestedObject> name='nestedField' prefix='fieldOfParent.'

Note string `fieldOfParent.` wont be type checked.

 */
export function FieldText(_a) {
    var { title, name, required, description, className, inputClass, wrapperClass, index, prefix, sensitive } = _a, otherProps = __rest(_a, ["title", "name", "required", "description", "className", "inputClass", "wrapperClass", "index", "prefix", "sensitive"]);
    const strName = inputName(name, prefix, index);
    const [[inputId]] = React.useState(() => uniqueId(strName));
    const [field, meta] = useField(strName);
    const fieldRef = React.useRef(null);
    useFocusOnError({ fieldRef, name });
    return (React.createElement("div", { className: className || 'sign-block' },
        !!title && React.createElement(Label, { title: title, forHtml: inputId, required: required }),
        React.createElement("div", { className: wrapperClass || 'controls' },
            React.createElement("textarea", Object.assign({ className: classnames(inputClass || 'input-bl', { error_border: meta.error && meta.touched, LoNotSensitive: !sensitive }) }, field, { ref: fieldRef, 
                // required={required}
                id: inputId }, otherProps))),
        !!description && React.createElement("div", { className: "small-txt" }, description),
        React.createElement(ErrorMessage, { name: strName }, (msg) => React.createElement(ErrorText, { error: msg }))));
}
