import { IS_SSR } from 'constants/isssr';
import { getEnv } from 'environments';
import * as React from 'react';
export const LOWidget = () => {
    const [ready, setReady] = React.useState(false);
    const [done, setDone] = React.useState(false);
    React.useEffect(() => {
        setReady(!IS_SSR);
    });
    React.useEffect(() => {
        if (ready && !done) {
            // eslint-disable-next-line no-underscore-dangle
            window.__lo_site_id = getEnv().luckyOrangeKey;
            const wa = document.createElement('script');
            wa.type = 'text/javascript';
            wa.async = true;
            wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
            const s = document.getElementsByTagName('script')[0];
            if (s && s.parentNode) {
                s.parentNode.insertBefore(wa, s);
            }
            setDone(true);
        }
    }, [done, ready]);
    return null;
};
