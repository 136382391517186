import * as React from 'react';
import Page from 'components/Page';
import { Main } from 'components/Main';
import { Spinner } from 'components/Spinner';
const ViewSingleMessageRaw = React.lazy(() => import(/* webpackChunkName: "ViewSingleMessagePage" */ './ViewSingleMessage'));
// in legacy there're no title and description
export const ViewSingleMessage = () => (React.createElement(Page, { title: "Notification", description: "Manage Inbox" },
    React.createElement(Main, { sidebar: true },
        React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
            React.createElement(ViewSingleMessageRaw, null)))));
