var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AccessDenied } from 'containers/AccessDenied';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation, } from 'react-router';
import { selectIsLoggedIn, selectUserRoles } from 'redux/auth/selectors';
import { ApplicationRoutes } from '../routes';
/* eslint-disable react/jsx-props-no-spreading */
const PrivateRoute = (_a) => {
    var { fallbackComponent: Fallback, requireRole, component: Component } = _a, rest = __rest(_a, ["fallbackComponent", "requireRole", "component"]);
    const route = useLocation().pathname;
    const isAdminPath = route.startsWith('/admin');
    const isAAPath = route.startsWith('/attorney');
    // const isClientPath = route.startsWith('/client');
    // eslint-disable-next-line no-nested-ternary
    const loginPath = isAdminPath ? ApplicationRoutes.adminLogin : (isAAPath ? ApplicationRoutes.attorneyLogin : ApplicationRoutes.login);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const currentRoles = useSelector(selectUserRoles);
    const hasPermission = React.useMemo(() => {
        if (!isLoggedIn) {
            return false;
        }
        if (typeof requireRole !== 'undefined') {
            if (Array.isArray(requireRole)) {
                return !!requireRole.find((r) => currentRoles.findIndex((cr) => cr === r) !== -1);
            }
            return currentRoles.findIndex((cr) => cr === requireRole) !== -1;
        }
        return true;
    }, [currentRoles, requireRole, isLoggedIn]);
    return (Component ? (React.createElement(Route, Object.assign({}, rest, { render: (props) => {
            if (hasPermission) {
                return React.createElement(Component, Object.assign({}, props));
            }
            if (!isLoggedIn) {
                return (Fallback ? React.createElement(Fallback, Object.assign({}, props))
                    : React.createElement(Redirect, { to: { pathname: loginPath, search: `?returnTo=${route}` } }));
            }
            return (Fallback ? React.createElement(Fallback, Object.assign({}, props)) : React.createElement(AccessDenied, { requireRole: requireRole }));
        } }))) : null);
};
export default PrivateRoute;
