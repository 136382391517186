import React from 'react';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { ApplicationRoutes } from 'containers/System/routes';
import { isAdminDomain } from 'utils/forceAdmin';
import { setFlagAfterSSRRender } from 'utils/setAfterSSR';
const PublicMenu = () => {
    const showAdmin = setFlagAfterSSRRender(isAdminDomain);
    return (React.createElement("div", { className: "res-men" },
        React.createElement(Accordion, null,
            React.createElement(Accordion.Toggle, { as: "div", className: "navbar-header", eventKey: "menu" },
                React.createElement("button", { type: "button", className: "navbar-toggle" },
                    React.createElement("span", { className: "sr-only" }, "Toggle navigation"),
                    React.createElement("span", { className: "icon-bar" }),
                    React.createElement("span", { className: "icon-bar" }),
                    React.createElement("span", { className: "icon-bar" })),
                React.createElement("a", { role: "button", className: "navbar-brand" }, "Menu")),
            React.createElement(Accordion.Collapse, { eventKey: "menu", className: "mobile_client_dashboard_drpdwn" },
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.aboutUs }, "About Us")),
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.services }, "Services")),
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.courtInformation }, "Court Information")),
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.contact }, "Contact")),
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.login }, "Request an Appearance")))),
            React.createElement(Accordion.Toggle, { as: "div", className: "navbar-header", eventKey: "login" },
                React.createElement("button", { type: "button", className: "navbar-toggle" },
                    React.createElement("span", { className: "sr-only" }, "Toggle navigation"),
                    React.createElement("span", { className: "icon-bar" }),
                    React.createElement("span", { className: "icon-bar" }),
                    React.createElement("span", { className: "icon-bar" })),
                React.createElement("a", { role: "button", className: "navbar-brand" }, "Login")),
            React.createElement(Accordion.Collapse, { eventKey: "login", className: "mobile_log_men" },
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.login }, "Request an Appearance")),
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.whyJoin }, "Appearance Attorney")),
                    React.createElement("li", null,
                        React.createElement("a", { href: "https://lawbeez.com", target: "_blank", rel: "noreferrer" }, "Hire Freelance Attorney")),
                    showAdmin && React.createElement("li", { className: "admin-link" },
                        React.createElement(Link, { to: ApplicationRoutes.whyJoin }, "Administrator")))),
            React.createElement(Accordion.Toggle, { as: "div", className: "navbar-header", eventKey: "signup" },
                React.createElement("button", { type: "button", className: "navbar-toggle" },
                    React.createElement("span", { className: "sr-only" }, "Toggle navigation"),
                    React.createElement("span", { className: "icon-bar" }),
                    React.createElement("span", { className: "icon-bar" }),
                    React.createElement("span", { className: "icon-bar" })),
                React.createElement("a", { role: "button", className: "navbar-brand" }, "Sign Up")),
            React.createElement(Accordion.Collapse, { eventKey: "signup", className: "mobile_sign_men" },
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.clientSignup }, "I Need Appearance Coverage")),
                    React.createElement("li", null,
                        React.createElement(Link, { to: ApplicationRoutes.attorneySignup }, "Appearance Attorney")))))));
};
export default PublicMenu;
