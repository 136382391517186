import { ContentWrapper } from 'components/ContentWrapper';
import Page from 'components/Page';
import { ApplicationRoutes } from 'containers/System/routes';
import * as React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AccessDenied = (props) => (React.createElement(Page, { title: "Access Denied", keywords: "access denied" },
    React.createElement(Helmet, null,
        React.createElement("meta", { name: "robots", content: "noindex" }),
        React.createElement("meta", { name: "googlebot", content: "noindex" })),
    React.createElement(ContentWrapper, { className: "col-lg-6 login-wrap" },
        React.createElement("div", { className: "col-lg-12 col-md-12 col-sm-12 login-form" },
            React.createElement("p", null, "\u00A0"),
            React.createElement("h4", { className: "text-center" }, "You are logged into another account on this browser."),
            React.createElement("p", null, "\u00A0"),
            React.createElement("p", null, "\u00A0"),
            React.createElement("p", { className: "text-center" },
                "In order to access your alternate account you will first need to",
                ' ',
                React.createElement(Link, { to: ApplicationRoutes.logout }, "log out"),
                ' ',
                "of all other accounts."),
            React.createElement("p", null, "\u00A0")))));
