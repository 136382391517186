import { ServiceType } from 'constants/serviceType';
import bankruptcyImg from 'containers/Main/images/bankruptcy.jpg';
import civilImg from 'containers/Main/images/civil.jpg';
import collectionsImg from 'containers/Main/images/collections.jpg';
import criminalImg from 'containers/Main/images/criminal.jpg';
import familyLawImg from 'containers/Main/images/family_law.jpg';
import immigrationImg from 'containers/Main/images/immigration.jpg';
import localCounselImg from 'containers/Main/images/local_counsel.jpg';
import proHacViceImg from 'containers/Main/images/pro_hac_vice.jpg';
import telephonicImg from 'containers/Main/images/telephonic.jpeg';
import probateImg from 'containers/Main/images/probate.jpg';
import workerCompensationImg from 'containers/Main/images/worker_compensation.jpg';
export const services = [
    {
        id: ServiceType.Bankruptcy,
        name: 'Bankruptcy',
        text: 'Our experienced Bankruptcy attorneys will cover your hearings with care.'
            + ' Covering, Chapter 7 & 13 341 meetings, Adversary Proceeding hearings, etc...',
        img: bankruptcyImg,
    },
    {
        id: ServiceType.Civil,
        name: 'Civil',
        text: 'One of our experienced Civil attorneys will handle any of your civil hearings.'
            + ' Covering CMC’s, MSJ’s, Trials, etc...',
        img: civilImg,
    },
    {
        id: ServiceType.Collections,
        name: 'Collections',
        text: 'Our experiences Collection attorneys will cover your collection hearings with'
            + ' care. Covering, Trials, MSJ’s CMC’s, etc...',
        img: collectionsImg,
    },
    {
        id: ServiceType.Criminal,
        name: 'Criminal',
        text: 'Our experienced criminal defense attorneys will handle your appearance with care.'
            + ' Covering arraignments, trials, expungement motions, etc...',
        img: criminalImg,
    },
    {
        id: ServiceType.FamilyLaw,
        name: 'Family Law',
        text: 'Our experienced Family Law attorneys will cover your hearings with care. Covering'
            + ' Child Custody hearings, Motion Hearings, TRO’s, etc...',
        img: familyLawImg,
    },
    {
        id: ServiceType.Immigration,
        name: 'Immigration',
        text: 'One of our experienced Immigration attorneys will cover your hearings with care.'
            + ' Covering Master Calendars, Bond Hearings, etc...',
        img: immigrationImg,
    },
    {
        id: ServiceType.Probate,
        name: 'Probate',
        text: 'Our experienced Probate attorneys will cover your Probate hearings. Covering,'
            + ' Conservatorship Hearings, Financial Accounting Hearings, etc...',
        img: probateImg,
    },
    {
        id: ServiceType.WorkerCompensation,
        name: 'Worker Compensation',
        text: 'Our experiences Worker’s Comp attorneys can handle all types of hearings for both'
            + ' plaintiff’s and applicants. Covering depositions, MSC’s, etc...',
        img: workerCompensationImg,
    },
    {
        id: ServiceType.LocalCounsel,
        name: 'Local Counsel',
        text: 'One of our local attorneys can act as your local counsel in the state they are'
            + ' licensed in.',
        img: localCounselImg,
    },
    {
        id: ServiceType.ProHacVice,
        name: 'Pro Hac Vice',
        text: 'One of our attorneys can sign on Pro Hac Vice for you once you’ve completed the'
            + ' application for that state.',
        img: proHacViceImg,
    },
    {
        id: ServiceType.Telephonic,
        name: 'Telephonic',
        text: '',
        img: telephonicImg,
    },
];
