import { Main } from 'components/Main';
import Page from 'components/Page';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
const EditFormRaw = React.lazy(() => import(/* webpackChunkName: "EditAppearance" */ 'containers/Client/ManageApps/EditAppearance/EditForm'));
export const EditAppearance = () => (React.createElement(Page, { title: "Edit an Appearance", description: "Edit an Appearance for an Attorney" },
    React.createElement(Main, { sidebar: true },
        React.createElement("div", { className: "container-fluid att-main no-pad-r" },
            React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
                React.createElement(EditFormRaw, null))))));
