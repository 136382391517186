/* eslint-disable lines-between-class-members,no-underscore-dangle */
import { transform } from './utils';
class Showcase {
    constructor(element, options) {
        this.element = element;
        if (this.element.nodeName === 'IMG') {
            this.fullWidth = this.element.width;
            this.fullHeight = this.element.height;
        }
        else {
            this.element.style.display = 'inline-block';
            this.fullWidth = this.element.offsetWidth;
            this.fullHeight = this.element.offsetHeight;
        }
        this.element.style.position = 'absolute';
        if (transform && options.transforms)
            this.element.style[`${transform}Origin`] = '0 0';
        this._options = options;
    }
    moveTo(x, y, scale) {
        this.width = this.fullWidth * scale;
        this.height = this.fullHeight * scale;
        this.x = x;
        this.y = y;
        this.scale = scale;
        const { style } = this.element;
        // eslint-disable-next-line no-bitwise
        style.zIndex = scale * 100 | 0;
        if (scale === 1) {
            this.element.style.transition = 'background 0.5s linear 0s';
            this.element.style.background = '#fff';
            this.element.style.opacity = 1;
            this.element.style.border = '2px solid #465c6a';
        }
        else {
            this.element.style.background = '#dfdfdf';
            this.element.style.opacity = 0.8;
            this.element.style.border = 'none';
        }
        if (transform && this._options.transforms) {
            style[transform] = ` translate(${x}px, ${y}px) scale(${scale})`;
        }
        else {
            // The gap between the image and its reflection doesn't resize automatically
            if (this._options.mirror && this.element.nodeName === 'IMG') {
                this.reflection.style.marginTop = `${this._options.mirror.gap * scale}px`;
            }
            style.width = `${this.width}px`;
            style.left = `${x}px`;
            style.top = `${y}px`;
        }
    }
}
export default Showcase;
