export const transform = (function transform() {
    const vendors = ['webkit', 'moz', 'ms'];
    const { style } = document.createElement('div');
    let trans = 'transform' in style ? 'transform' : undefined;
    for (let i = 0, count = vendors.length; i < count; i += 1) {
        const prop = `${vendors[i]}Transform`;
        if (prop in style) {
            trans = prop;
            break;
        }
    }
    return trans;
}());
export const time = !window.performance || !window.performance.now
    ? function time() { return +new Date(); }
    : function time() { return performance.now(); };
export const cancelFrame = window.cancelAnimationFrame;
export const requestFrame = window.requestAnimationFrame;
