import { createStore, applyMiddleware, } from 'redux';
import reduxSaga from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { setStoreInstance } from 'redux/storeInstance';
import { rootSaga } from './saga';
import { createReducer } from './reducer';
export const startStore = (initialState, history) => {
    // create the saga middleware
    const sagaMiddleware = reduxSaga();
    // compose middleware
    const middlewares = [sagaMiddleware, routerMiddleware(history)];
    const enhancers = [applyMiddleware(...middlewares)];
    const store = createStore(createReducer({}, history), initialState, composeWithDevTools({
        name: 'Attorneys In Motion',
    })(...enhancers));
    store.injectedReducers = {};
    store.injectedSagas = {};
    store.runSaga = sagaMiddleware.run;
    // then run the saga
    sagaMiddleware.run(rootSaga);
    setStoreInstance(store);
    return store;
};
