var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Carousel from './Carousel';
import './styles.css';
class Carousel3d extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.wrapperRef = null;
        this.showcasesRefs = [];
        this.carousel = null;
        this.init = () => {
            const _a = this.props, { children } = _a, options = __rest(_a, ["children"]);
            this.carousel = new Carousel(this.showcasesRefs, this.wrapperRef, options);
        };
        this.assignShowcaseRef = (ref) => {
            if (ref) {
                this.showcasesRefs.push(ref);
            }
        };
        this.assignWrapperRef = (ref) => {
            if (ref) {
                this.wrapperRef = ref;
            }
        };
    }
    componentDidMount() {
        if (this.showcasesRefs.length) {
            this.init();
        }
    }
    render() {
        const { children } = this.props;
        return (React.createElement("div", { ref: this.assignWrapperRef, className: "noselect" }, React.Children.map(children, (child, index) => (React.createElement("div", { className: "showcase-wrapper", key: index, ref: this.assignShowcaseRef }, child)))));
    }
}
// eslint-disable-next-line react/static-property-placement
Carousel3d.defaultProps = {
    xOrigin: null,
    yOrigin: null,
    xRadius: null,
    yRadius: null,
    farScale: 0.5,
    transforms: true,
    smooth: true,
    fps: 30,
    speed: 4,
    autoPlay: 0,
    autoPlayDelay: 2000,
    bringToFront: true,
    handle: 'carousel',
};
export default Carousel3d;
