import * as React from 'react';
import Page from 'components/Page';
import Banner from './Banner';
import LawyerBenefits from './LawyerBenefits';
import CourtInformation from './CourtInformation';
import AttorneyService from './AttorneyService';
import BottomContent from './BottomContent';
export const Main = () => (React.createElement("div", null,
    React.createElement(Page, { title: "Special Appearance Attorneys - Attorneys in Motion", description: "Special Appearance attorneys - Attorneys in Motion provides special appearance attorney\n      /per diem services for all case types and all areas of law. Available nationwide!", keywords: "Home, homepage" },
        React.createElement(Banner, null),
        React.createElement(LawyerBenefits, null),
        React.createElement(AttorneyService, null),
        React.createElement(CourtInformation, null),
        React.createElement(BottomContent, null))));
