export const pushToGTMDataLayer = (e) => {
    if (!window.dataLayer) {
        window.dataLayer = [];
    }
    const { dataLayer } = window;
    dataLayer.push(e);
};
export const gtmClientLogin = () => {
    pushToGTMDataLayer({
        event: 'user-event',
        'ee-event-category': 'Login',
        'ee-event-action': 'Request an Appearance',
        'ee-event-label': 'Complete',
    });
};
export const gtmAALogin = () => {
    pushToGTMDataLayer({
        event: 'user-event',
        'ee-event-category': 'Login',
        'ee-event-action': 'Appearance Attorney',
        'ee-event-label': 'Complete',
    });
};
export const gtmClientSignUp = (step) => {
    pushToGTMDataLayer({
        event: 'user-event',
        'ee-event-category': 'Signup',
        'ee-event-action': 'I Need Appearance Coverage',
        'ee-event-label': typeof step === 'undefined' ? 'Complete' : (step + 1).toString(),
    });
};
export const gtmAASignUp = (step) => {
    pushToGTMDataLayer({
        event: 'user-event',
        'ee-event-category': 'Login',
        'ee-event-action': 'Appearance Attorney',
        'ee-event-label': typeof step === 'undefined' ? 'Complete' : (step + 1).toString(),
    });
};
export const gtmContactUs = () => {
    pushToGTMDataLayer({
        event: 'user-event',
        'ee-event-category': 'Contact Us',
        'ee-event-action': 'Form',
        'ee-event-label': 'Complete',
    });
};
