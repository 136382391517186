import { ContentWrapper } from 'components/ContentWrapper';
import Page from 'components/Page';
import { Spinner } from 'components/Spinner';
import * as React from 'react';
import { prerenderedLoadable } from 'utils/prefetch';
const ForgotPasswordRaw = prerenderedLoadable(() => import(/* webpackChunkName: "ResetPasswordPage" */ './Page'));
export const ResetPassword = () => (React.createElement(Page, { title: "Reset Password", description: "Password reset form" },
    React.createElement(ContentWrapper, null,
        React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
            React.createElement(ForgotPasswordRaw, null)))));
