import { PushNotificationDetails } from 'containers/Modals/ViewPushNotification/PushNotificationDetails';
import { Logger } from 'logger';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearPushNotification } from 'redux/notifications/actions';
import { selectLastPushNotification } from 'redux/notifications/selectors';
/* eslint-disable react/jsx-props-no-spreading */
export const PushNotificationPopup = () => {
    const pushNotification = useSelector(selectLastPushNotification);
    const show = pushNotification && (pushNotification.title || pushNotification.data.title);
    if (pushNotification && !show) {
        Logger.error('Push notification payload has not title', pushNotification);
    }
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(clearPushNotification());
    };
    return (React.createElement(React.Fragment, null, pushNotification && show && (React.createElement(PushNotificationDetails, { data: { notification: pushNotification }, close: handleClose }))));
};
