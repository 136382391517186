import favIcon from 'assets/favicon.ico';
import { jsonLDData } from 'components/Page/json.ld.data';
import { getEnv, GOOGLE_SITE_VERIFY, MS_VALIDATE } from 'environments';
import React from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import ScrollToTop from 'components/ScrollToTop';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { statPageView } from 'redux/analytics/calls';
export const defaultTitle = 'Attorneys In Motion';
export const defaultDescription = 'Special Appearance attorneys - Attorneys in Motion provides special appearance attorney '
    + '/per diem services for all case types and all areas of law. Available nationwide!';
const Page = ({ dontTrack, children, title, description, keywords, canonical, }) => {
    const location = useLocation().pathname;
    const { host } = getEnv();
    const [lastLocation, setLastLocation] = React.useState(null);
    const canonicalUrl = `${host}${canonical || location}`;
    if (!dontTrack) {
        if (lastLocation !== location) {
            statPageView(location, title);
            setLastLocation(location);
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, { defaultTitle: defaultTitle, title: title },
            React.createElement("meta", { name: "description", content: description || defaultDescription }),
            React.createElement("link", { rel: "icon", href: favIcon }),
            React.createElement("link", { rel: "canonical", href: canonicalUrl }),
            keywords && React.createElement("meta", { name: "keywords", content: keywords }),
            React.createElement("meta", { name: "google-site-verification", content: GOOGLE_SITE_VERIFY }),
            React.createElement("meta", { name: "msvalidate.01", content: MS_VALIDATE }),
            React.createElement("meta", { name: "robots", content: "" }),
            React.createElement("meta", { name: "googlebot", content: "" }),
            React.createElement("script", { key: "ld-data", type: "application/ld+json" }, JSON.stringify(jsonLDData)),
            React.createElement("meta", { name: "apple-itunes-app", content: `app-id=1493170905, app-argument=${location}` }),
            React.createElement("meta", { name: "google-play-app", content: "app-id=com.attorneysinmotion.app" })),
        React.createElement(Header, null),
        React.createElement(React.Fragment, null, children),
        React.createElement(ScrollToTop, null),
        React.createElement(Footer, null)));
};
export const SetPageAs = ({ title, description, }) => {
    const location = useLocation();
    statPageView(location.pathname, title);
    return (React.createElement(Helmet, { title: title }, description && React.createElement("meta", { name: "description", content: description || defaultDescription })));
};
export default Page;
