import { isMobileApp } from 'constants/isMobileApp';
import { AdminAAPayroll } from 'containers/Admin/AAPayroll';
import { AdminAAPayrollHistory } from 'containers/Admin/AAPayrollHistory';
import { AdminAccount } from 'containers/Admin/Account';
import { AdminAppearanceDetails } from 'containers/Admin/AdminAppearanceDetails';
import { AdminInvoiceDetails } from 'containers/Admin/AdminInvoiceDetails';
import { AllAppearances } from 'containers/Admin/AllAppearances';
import { AppLogs } from 'containers/Admin/AppLogs';
import { AttList } from 'containers/Admin/AttList';
import { AdminAttorneyDetails } from 'containers/Admin/AttorneyDetails';
import { AdminClientDetails } from 'containers/Admin/ClientDetails';
import { ClientList } from 'containers/Admin/ClientList';
import { EmailTemplate } from 'containers/Admin/EmailTemplate';
import { AdminAddEmailTemplate } from 'containers/Admin/EmailTemplate/AddEmailTemplate';
import { AdminEditEmailTemplate } from 'containers/Admin/EmailTemplate/EditEmailTemplate';
import { AdminManageCounty } from 'containers/Admin/ManageCounty';
import { AdminAddCounty } from 'containers/Admin/ManageCounty/AddCounty';
import { AdminEditCounty } from 'containers/Admin/ManageCounty/EditCounty';
import { AdminManageCoupon } from 'containers/Admin/ManageCoupon';
import { AdminAddCoupon } from 'containers/Admin/ManageCoupon/AddCoupon';
import { AdminEditCoupon } from 'containers/Admin/ManageCoupon/EditCoupon';
import { AdminManageCourts } from 'containers/Admin/ManageCourts';
import { AdminAddCourt } from 'containers/Admin/ManageCourts/AddCourt';
import { AdminEditCourt } from 'containers/Admin/ManageCourts/EditCourt';
import { AdminManageInvoices } from 'containers/Admin/ManageInvoices';
import { AdminManageStandardFees } from 'containers/Admin/ManageStandardFees';
import { AdminAddStandardFee } from 'containers/Admin/ManageStandardFees/AddStandardFee';
import { AdminEditStandardFee } from 'containers/Admin/ManageStandardFees/EditStandardFee';
import { AdminManageStates } from 'containers/Admin/ManageStates';
import { AdminAddState } from 'containers/Admin/ManageStates/AddState';
import { AdminEditState } from 'containers/Admin/ManageStates/EditState';
import { NonStandardFeesForm } from 'containers/Admin/NonStandardFees';
import { Notifications } from 'containers/Admin/Notifications';
import { PayDueAppearances } from 'containers/Admin/PayDueAppearances';
import { ViewConversationList } from 'containers/Admin/ViewConversationList';
import { ViewSingleMessage } from 'containers/Admin/ViewSingleMessage';
import { ApplicationUpdate } from 'containers/ApplicationUpdate';
import { AttorneyAccount } from 'containers/Attorney/Account';
import { AvailableApps } from 'containers/Attorney/AvailableApps';
import { MailRejectAppearance } from 'containers/Attorney/Mail/reject';
import { ManageAppsDetails } from 'containers/Attorney/ManageApps/ManageAppsDetails';
import { AvailableAppsDetails } from 'containers/Attorney/AvailableApps/AvailableAppsDetails';
import { AttorneyDashboard } from 'containers/Attorney/Dashboard';
import { ManageApps } from 'containers/Attorney/ManageApps';
import { AttorneySignUp } from 'containers/Attorney/SignUp';
import { SubmittableApps } from 'containers/Attorney/SubmittableApps';
import { SubmitDetails } from 'containers/Attorney/SubmittableApps/SubmitDetails';
import { ClientAccount } from 'containers/Client/Account';
import { AppearanceResultDetails } from 'containers/Client/AppearanceDetails';
import { AppearanceDetailsForTemplate } from 'containers/Client/TemplateFromAppearance';
import { TemplateDetailsForAppearance } from 'containers/Client/AppearanceFromTemplate';
import { TemplateView } from 'containers/Client/TemplateView';
import { ClientDashboard } from 'containers/Client/Dashboard';
import { ManageApps as ManageClientApps } from 'containers/Client/ManageApps';
import { ManageTemplates as ManageClientTemplates } from 'containers/Client/ManageTemplates';
import { EditAppearance } from 'containers/Client/ManageApps/EditAppearance';
import { PrintInvoice } from 'containers/Client/PrintInvoice';
import { ReceiptsAndInvoices } from 'containers/Client/ReceiptsAndInvoices';
import { ResultsOfApps } from 'containers/Client/ResultsOfApps';
import { ScheduleAppearance } from 'containers/Client/ScheduleAppearance';
import { ClientSignUp } from 'containers/Client/SignUp';
import { ViewInvoiceDetails } from 'containers/Client/ViewInvoice';
import { ContactUsPage } from 'containers/Contact';
import { AboutUs } from 'containers/Content/AboutUs';
import { CourtInformation } from 'containers/Content/CourtInformation';
import { FAQ } from 'containers/Content/FAQ';
import { PrivacyPolicy } from 'containers/Content/PrivacyPolicy';
import { Security } from 'containers/Content/Security';
import { Services } from 'containers/Content/Services';
import { Sitemap } from 'containers/Content/Sitemap';
import { TermsConditions } from 'containers/Content/TermsConditions';
import { Events } from 'containers/Events';
import { ForgotPassword } from 'containers/ForgotPassword';
import { Login } from 'containers/Login';
import { Logout } from 'containers/Logout';
import { Main } from 'containers/Main';
import { Messages } from 'containers/Messages';
import { ViewMessage } from 'containers/Messages/ViewMessage';
import { NotFound } from 'containers/NotFound';
import { ResetPassword } from 'containers/ResetPassword';
import PrivateRoute from 'containers/System/PrivateRoute';
import { ApplicationRoutes } from 'containers/System/routes';
import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { BothAdmins, MainAdmin, UserRole } from 'redux/auth/constants';
/* eslint-disable react/jsx-props-no-spreading */
export const Router = () => {
    // Scroll to top on page change
    const match = useRouteMatch();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [match]);
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: ApplicationRoutes.login, component: Login }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.logout, component: Logout }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.attorneyLogin, render: (props) => React.createElement(Login, Object.assign({ isAttorney: true }, props)) }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.adminLogin, render: (props) => React.createElement(Login, Object.assign({ isAdmin: true }, props)) }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.clientSignUp, component: ClientSignUp }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.attorneySignUp, component: AttorneySignUp }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.setPassword, component: ResetPassword }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.recoverPassword, component: ForgotPassword }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.adminChangePassword, component: ResetPassword }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.adminForgotPassword, component: ForgotPassword }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.aboutUs, component: AboutUs }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.faq, component: FAQ }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.services, component: Services }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.security, component: Security }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.courtInformation, component: CourtInformation }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.termsAndConditions, component: TermsConditions }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.privacyPolicy, component: PrivacyPolicy }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.sitemap, component: Sitemap }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.contact, component: ContactUsPage }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ATTORNEY, path: ApplicationRoutes.attorneyDashboard, component: AttorneyDashboard }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ATTORNEY, path: ApplicationRoutes.attorneyAvailableAppearance, component: AvailableApps }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ATTORNEY, path: ApplicationRoutes.attorneyManageAppearance, component: ManageApps }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ATTORNEY, path: ApplicationRoutes.attorneyManageAppearanceDetails, component: ManageAppsDetails }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ATTORNEY, path: ApplicationRoutes.attorneySubmitResults, component: SubmittableApps }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientDashboard, component: ClientDashboard }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientRequestAppearance, component: ScheduleAppearance }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientAccount, component: ClientAccount }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientManageAppearance, component: ManageClientApps }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientSubmittedAppearance, component: ResultsOfApps }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientViewPayments, component: ReceiptsAndInvoices }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientPrintInvoice, component: PrintInvoice }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientViewInvoice, component: ViewInvoiceDetails }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientViewAppearanceResultDetails, component: AppearanceResultDetails }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientCreateTemplateFromAppearance, component: AppearanceDetailsForTemplate }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientCreateTemplate, component: AppearanceDetailsForTemplate }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientCreateAppearanceFromTemplate, component: TemplateDetailsForAppearance }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientViewTemplateDetails, component: TemplateView }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientManageTemplate, component: ManageClientTemplates }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.CLIENT, path: ApplicationRoutes.clientEditAppearance, component: EditAppearance }),
        React.createElement(PrivateRoute, { exact: true, requireRole: [
                UserRole.ATTORNEY,
                UserRole.CLIENT,
                UserRole.ADMIN,
                UserRole.ADMIN_LIMITED,
            ], path: ApplicationRoutes.messages, component: Messages }),
        React.createElement(PrivateRoute, { exact: true, requireRole: [
                UserRole.ATTORNEY,
                UserRole.CLIENT,
                UserRole.ADMIN,
                UserRole.ADMIN_LIMITED,
            ], path: ApplicationRoutes.events, component: Events }),
        React.createElement(PrivateRoute, { exact: true, requireRole: [
                UserRole.ATTORNEY,
                UserRole.CLIENT,
                UserRole.ADMIN,
                UserRole.ADMIN_LIMITED,
            ], path: ApplicationRoutes.viewMessage, component: ViewMessage }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminNotifications, component: Notifications }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageLog, component: AppLogs }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.viewAdminNotification, component: ViewSingleMessage }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ATTORNEY, path: ApplicationRoutes.attorneyAvailableAppearanceDetails, component: AvailableAppsDetails }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ATTORNEY, path: ApplicationRoutes.attorneyMailAccepAppearance, component: AvailableAppsDetails }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ATTORNEY, path: ApplicationRoutes.attorneyMailRejectAppearance, component: MailRejectAppearance }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ATTORNEY, path: ApplicationRoutes.attorneySubmitResultsDetails, component: SubmitDetails }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ATTORNEY, path: ApplicationRoutes.attorneyAccount, component: AttorneyAccount }),
        React.createElement(PrivateRoute, { exact: true, requireRole: MainAdmin, path: ApplicationRoutes.adminAccount, component: AdminAccount }),
        React.createElement(PrivateRoute, { exact: true, requireRole: MainAdmin, path: ApplicationRoutes.adminAccountEmails, component: AdminAccount }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageCourts, component: AdminManageCourts }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminAddCourt, component: AdminAddCourt }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminEditCourt, component: AdminEditCourt }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageCounty, component: AdminManageCounty }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminAddCounty, component: AdminAddCounty }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminEditCounty, component: AdminEditCounty }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageState, component: AdminManageStates }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminAddState, component: AdminAddState }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminEditState, component: AdminEditState }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageCoupon, component: AdminManageCoupon }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminAddCoupon, component: AdminAddCoupon }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminEditCoupon, component: AdminEditCoupon }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminAddEmailTemplate, component: AdminAddEmailTemplate }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminEditEmailTemplate, component: AdminEditEmailTemplate }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminNonStandardFees, component: NonStandardFeesForm }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminDashboard, component: AllAppearances }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageAppearances, component: AllAppearances }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageDueAppearances, component: PayDueAppearances }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageClientList, component: ClientList }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminClientDetails, component: AdminClientDetails }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminAttorneyDetails, component: AdminAttorneyDetails }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageAAList, component: AttList }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageEmailTemplates, component: EmailTemplate }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminViewConversationList, component: ViewConversationList }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminViewAppearanceDetails, component: AdminAppearanceDetails }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminManageStandardFees, component: AdminManageStandardFees }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminAddStandardFee, component: AdminAddStandardFee }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminInvoiceDetails, component: AdminInvoiceDetails }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminEditStandardFee, component: AdminEditStandardFee }),
        React.createElement(PrivateRoute, { exact: true, requireRole: BothAdmins, path: ApplicationRoutes.adminInvoiceHistory, component: AdminManageInvoices }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ADMIN, path: ApplicationRoutes.adminAAPayrollHistory, component: AdminAAPayrollHistory }),
        React.createElement(PrivateRoute, { exact: true, requireRole: UserRole.ADMIN, path: ApplicationRoutes.adminAAPayroll, component: AdminAAPayroll }),
        React.createElement(Route, { exact: true, path: ApplicationRoutes.root, component: Main }),
        isMobileApp && (React.createElement(Route, { exact: true, path: ApplicationRoutes.applicationUpdate, component: ApplicationUpdate })),
        React.createElement(Route, { path: ApplicationRoutes.notFound, component: NotFound }),
        React.createElement(Route, { component: NotFound })));
};
