import { callOnLeave } from 'hooks/callOnLeave';
import * as React from 'react';
export const useServerValidate = (isServerLoading, serverError, isSubmitting, setErrors, setStatus, setSubmitting) => {
    const [err, setErr] = React.useState(null);
    const callHappened = React.useRef(false); // used to ensure that setSubmitting(false) didn't happen before api call
    React.useEffect(() => {
        if (isServerLoading && isSubmitting) {
            callHappened.current = true;
        }
        if (!isServerLoading && isSubmitting && callHappened.current) {
            setSubmitting(false);
            callHappened.current = false;
            return;
        }
        if (serverError !== err) {
            setErr(serverError || null);
            if (serverError && serverError.errors) {
                setErrors(serverError.errors);
            }
            if (serverError) {
                setStatus(serverError.message);
            }
            else {
                setStatus(null);
            }
        }
    }, [serverError, isServerLoading, isSubmitting, err]);
    // Reset status when unmount or page change happens
    callOnLeave(() => setStatus(null));
};
