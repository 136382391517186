import { Main } from 'components/Main';
import Page from 'components/Page';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
const ScheduleFormRaw = React.lazy(() => import(/* webpackChunkName: "ScheduleAppearance" */ './ScheduleForm'));
export const ScheduleAppearance = () => (React.createElement(Page, { title: "Request an Appearance", description: "Request an Appearance for an Attorney" },
    React.createElement(Main, { sidebar: true },
        React.createElement("div", { className: "container-fluid att-main no-pad-r" },
            React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
                React.createElement(ScheduleFormRaw, null))))));
