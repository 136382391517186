import { getCourtListCount, initialCoutCountValue } from 'client/fetch/getCourtListCount';
import { ApplicationRoutes } from 'containers/System/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import usaImg from './images/usa-img.jpg';
const CourtPortion = ({ courtCount }) => (React.createElement("div", { className: "container-fluid no-pad" },
    React.createElement("div", { className: "container" },
        React.createElement("div", { className: "court-portion" },
            React.createElement("div", { className: "col-lg-12 no-pad" },
                React.createElement("div", { className: "court-part" },
                    React.createElement("h4", null, "Court Information"),
                    React.createElement("p", null, "Attorneys in Motion has attorneys who can cover hearings at any court location in the USA.")),
                React.createElement("div", { className: "court-box" },
                    React.createElement("div", { className: "cou-img" },
                        React.createElement("img", { src: usaImg, alt: "Appearance Coverage Area", title: "Appearance Coverage Area" })),
                    React.createElement("div", { className: "img-upper-port" },
                        React.createElement("div", { className: "numb-detail" },
                            React.createElement("div", { className: "number-count" },
                                React.createElement("span", null, courtCount)),
                            React.createElement("p", null, "Courts")),
                        React.createElement(Link, { to: ApplicationRoutes.courtInformation, className: "view-now" }, "View Now"))))))));
export default () => {
    const [courts, setCourts] = React.useState({
        isFetching: false,
        data: initialCoutCountValue,
    });
    getCourtListCount(setCourts);
    return React.createElement(CourtPortion, { courtCount: courts.data.value });
};
