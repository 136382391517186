import Page from 'components/Page';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
import { prerenderedLoadable } from 'utils/prefetch';
const ServicesPage = prerenderedLoadable(() => import(/* webpackChunkName: "Services" */ './Services'));
export const Services = () => (React.createElement(Page, { title: "Appearance Attorneys | Any Court | Any Time" },
    React.createElement("div", { className: "container-fluid no-pad " },
        React.createElement("div", { className: "basic-portion" },
            React.createElement("div", { className: "container" },
                React.createElement(React.Suspense, { fallback: React.createElement(Spinner, null) },
                    React.createElement(ServicesPage, null)))))));
