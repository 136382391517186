import { Main } from 'components/Main';
import Page from 'components/Page';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
const NonStandardFeesPageRaw = React.lazy(() => import(/* webpackChunkName: "NonStandardFeesPage" */ './Page'));
export const NonStandardFeesForm = () => (React.createElement(Page, { title: "Non Standard Fees" },
    React.createElement(Main, { sidebar: true },
        React.createElement("div", { className: "container-fluid att-main no-pad" },
            React.createElement("div", { className: "no-pad attorney-wrap " },
                React.createElement(React.Suspense, { fallback: React.createElement(Spinner, { full: true }) },
                    React.createElement(NonStandardFeesPageRaw, null)))))));
