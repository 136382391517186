import { getEnv } from 'environments';
import * as React from 'react';
import ReactGA from 'react-ga';
import { isDebug } from 'utils/isDebug';
export const GAWidget = () => {
    const [ready, setReady] = React.useState(false);
    React.useEffect(() => {
        setReady(true);
    });
    if (!ready) {
        return null;
    }
    // if (!IS_SSR) {
    ReactGA.initialize(getEnv().googleTrackingKey, { debug: isDebug });
    // }
    return null;
};
