import React from 'react';
import { Link } from 'react-router-dom';
import { ApplicationRoutes } from 'containers/System/routes';
import Tooltip from 'components/Tooltip';
import { CheckRole } from 'components/CheckRole';
import { UserRole } from 'redux/auth/constants';
const ClientSideBar = () => (React.createElement("ul", { className: "menu-left-bx" },
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "client-request-appearance", content: "Schedule\u00A0Appearance" },
            React.createElement(Link, { to: ApplicationRoutes.clientRequestAppearance },
                React.createElement("span", { className: "men-1" })))),
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "client-manage-template", content: "Clone\u00A0Appearance\u00A0from\u00A0Saved\u00A0Template" },
            React.createElement(Link, { to: ApplicationRoutes.clientManageTemplate },
                React.createElement("span", { className: "men-8" })))),
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "client-manage-appearance", content: "Manage\u00A0My\u00A0Appearances" },
            React.createElement(Link, { to: ApplicationRoutes.clientManageAppearance },
                React.createElement("span", { className: "men-2" })))),
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "client-submitted-appearance", content: "Appearance\u00A0Reports" },
            React.createElement(Link, { to: ApplicationRoutes.clientSubmittedAppearance },
                React.createElement("span", { className: "men-3" })))),
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "messages", content: "Messages" },
            React.createElement(Link, { to: ApplicationRoutes.messages },
                React.createElement("span", { className: "men-4" })))),
    React.createElement(CheckRole, { role: UserRole.CLIENT, fallback: null },
        React.createElement("li", null,
            React.createElement(Tooltip, { id: "client-view-payments", content: "Appearance\u00A0Receipts\u00A0and\u00A0Invoices" },
                React.createElement(Link, { to: ApplicationRoutes.clientViewPayments },
                    React.createElement("span", { className: "men-5" })))),
        React.createElement("li", null,
            React.createElement(Tooltip, { id: "client-account", content: "Manage\u00A0My\u00A0Account" },
                React.createElement(Link, { to: ApplicationRoutes.clientAccount },
                    React.createElement("span", { className: "men-6" }))))),
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "logout", content: "Logout" },
            React.createElement(Link, { to: ApplicationRoutes.logout },
                React.createElement("span", { className: "men-7" }))))));
export default ClientSideBar;
