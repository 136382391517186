import { axiosInstance } from 'client/axiosInstance';
import { API_PATHES } from 'client/constants';
import { Logger } from 'logger';
import { all, call, put, takeEvery, } from 'redux-saga/effects';
import { gtmContactUs } from 'redux/analytics/gtm';
import { getType } from 'typesafe-actions';
import { extractError } from 'utils/extractError';
import { contactUsAction } from './actions';
function* contactSaga(action) {
    try {
        const axios = axiosInstance();
        Logger.debug('Contact form request', action.payload);
        const raw = yield call(axios.post, API_PATHES.mail.contact, action.payload);
        yield put(contactUsAction.success(raw.data));
        yield call(gtmContactUs);
    }
    catch (err) {
        const error = extractError(err, 'Sorry, something was wrong, please try again');
        Logger.error('Contact us error', { error });
        yield put(contactUsAction.failure(error));
    }
}
export function* saga() {
    yield all([
        yield takeEvery(getType(contactUsAction.request), contactSaga),
    ]);
}
