import React from 'react';
import { Link } from 'react-router-dom';
import { ApplicationRoutes } from 'containers/System/routes';
const Links = () => (React.createElement("ul", { className: "footer-links" },
    React.createElement("li", null,
        React.createElement(Link, { to: ApplicationRoutes.security }, "Security")),
    React.createElement("li", { className: "dash-line" }, "|"),
    React.createElement("li", null,
        React.createElement(Link, { to: ApplicationRoutes.privacyPolicy }, "Privacy Policy")),
    React.createElement("li", { className: "dash-line" }, "|"),
    React.createElement("li", null,
        React.createElement(Link, { to: ApplicationRoutes.termsAndConditions }, "Terms & Condition")),
    React.createElement("li", { className: "dash-line" }, "|"),
    React.createElement("li", null,
        React.createElement(Link, { to: ApplicationRoutes.sitemap }, " Sitemap"))));
export default Links;
