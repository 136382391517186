import * as React from 'react';
import loadable from '@loadable/component';
import { PrerenderedComponent } from 'react-prerendered-component';
export const prerenderedLoadable = (dynamicImport) => {
    const LoadableComponent = loadable(dynamicImport);
    return React.memo((props) => (
    // you can use the `.preload()` method from react-loadable or react-imported-component`
    React.createElement(PrerenderedComponent, { live: LoadableComponent.load() },
        React.createElement(LoadableComponent, Object.assign({}, props)))));
};
