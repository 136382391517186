import * as React from 'react';
import classNames from 'classnames';
import throttle from 'lodash.throttle';
/**
 * Automates smooth transition by adding related classes
 * @prop appear - Adds intermediate stage classes on init, e.g. 'entering' -> 'exiting'
 * @prop show - Adds classes for show or for hide transitions
 * @prop triggerKey - Triggers transition on change (hide -> show)
 * @prop className - Unique class for transition
 * @prop delay - Amount of ms for intermediate stages
 * @prop renderKey - Triggers rerender without animation triggered
 */
var FadeStatus;
(function (FadeStatus) {
    FadeStatus["ENTERED"] = "entered";
    FadeStatus["ENTERING"] = "entering";
    FadeStatus["EXITED"] = "exited";
    FadeStatus["EXITING"] = "exiting";
})(FadeStatus || (FadeStatus = {}));
class Fade extends React.Component {
    constructor(props) {
        super(props);
        this.fadeOutTimeout = null;
        this.fadeInTimeout = null;
        this.onUpdate = (init, prevProps) => {
            const { show, triggerKey, delay, } = this.props;
            if ((init || (prevProps && prevProps.show)) && !show) {
                this.fadeOut();
            }
            else if ((init || (prevProps && !prevProps.show)) && show) {
                this.fadeIn();
            }
            else if (prevProps && prevProps.triggerKey !== triggerKey) {
                this.setState({
                    status: FadeStatus.EXITING,
                });
                this.fadeOutTimeout = setTimeout(() => {
                    this.fadeIn();
                }, delay);
            }
        };
        this.fadeIn = () => {
            const { delay, children } = this.props;
            this.setState({
                status: FadeStatus.ENTERING,
                render: children,
            });
            if (this.fadeInTimeout) {
                clearTimeout(this.fadeInTimeout);
            }
            this.fadeInTimeout = setTimeout(() => {
                this.fadeInTimeout = null;
                this.setState({ status: FadeStatus.ENTERED });
            }, delay);
        };
        this.fadeOut = () => {
            const { delay, children } = this.props;
            this.setState({
                status: FadeStatus.EXITING,
                render: children,
            });
            if (this.fadeOutTimeout) {
                clearTimeout(this.fadeOutTimeout);
            }
            this.fadeOutTimeout = setTimeout(() => {
                this.fadeOutTimeout = null;
                this.setState({ status: FadeStatus.EXITED });
            }, delay);
        };
        if (props.appear) {
            this.state = {
                render: props.children,
                status: props.show
                    ? FadeStatus.ENTERING
                    : FadeStatus.EXITING,
            };
        }
        else {
            this.state = {
                render: props.children,
                status: props.show
                    ? FadeStatus.ENTERED
                    : FadeStatus.EXITED,
            };
        }
        this.onUpdate = throttle(this.onUpdate, props.delay);
    }
    componentDidMount() {
        const { appear } = this.props;
        if (appear) {
            this.onUpdate(true);
        }
    }
    componentDidUpdate(prevProps) {
        const { show, triggerKey, renderKey, children, } = this.props;
        if (prevProps.renderKey !== renderKey) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ render: children });
        }
        if (prevProps.show !== show) {
            this.onUpdate(false, prevProps);
        }
        else if (prevProps.triggerKey !== triggerKey) {
            this.onUpdate(false, prevProps);
        }
    }
    componentWillUnmount() {
        if (this.fadeInTimeout) {
            clearTimeout(this.fadeInTimeout);
        }
        if (this.fadeOutTimeout) {
            clearTimeout(this.fadeOutTimeout);
        }
    }
    render() {
        const { className } = this.props;
        const { status, render } = this.state;
        return (React.createElement("div", { className: classNames(className, status) }, render));
    }
}
Fade.defaultProps = {
    show: true,
    appear: false,
    delay: 500,
    triggerKey: '',
};
export default Fade;
