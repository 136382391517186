import React from 'react';
import AttorneySideBar from 'components/SideBar/AttorneySideBar';
import ClientSideBar from 'components/SideBar/ClientSideBar';
import AdminSideBar from 'components/SideBar/AdminSideBar';
import { BothAdmins, UserRole } from 'redux/auth/constants';
import { CheckRole } from 'components/CheckRole';
const SideBar = () => (React.createElement("div", { className: "menu-left" },
    React.createElement(CheckRole, { role: UserRole.CLIENT, fallback: null },
        React.createElement(ClientSideBar, null)),
    React.createElement(CheckRole, { role: UserRole.ATTORNEY, fallback: null },
        React.createElement(AttorneySideBar, null)),
    React.createElement(CheckRole, { role: BothAdmins, fallback: null },
        React.createElement(AdminSideBar, null))));
export default SideBar;
