import React from 'react';
// import Blogs from './Blogs';
import ContactUs from './ContactUs';
import Tweets from './Tweets';
const BottomContent = () => (React.createElement("div", { className: "container-fluid no-pad" },
    React.createElement("div", { className: "bottom-content" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-lg-12 no-pad" },
                    React.createElement(ContactUs, null),
                    React.createElement(Tweets, null)))))));
export default BottomContent;
