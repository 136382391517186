import { CheckRole } from 'components/CheckRole';
import { actionsBraces } from 'components/Header/formatActions';
import { ApplicationRoutes } from 'containers/System/routes';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { UserRole } from 'redux/auth/constants';
const RoleBasedMenu = ({ role, items, }) => (React.createElement(CheckRole, { role: role, fallback: null },
    React.createElement(Accordion.Collapse, { eventKey: "menu", className: "mobile_client_dashboard_drpdwn" },
        React.createElement("ul", null, items.map((item, index) => (React.createElement("li", { key: item.label + index }, item.url ? (React.createElement("a", { href: item.url, target: "_blank", rel: "noreferrer" }, item.label)) : (item.route && React.createElement(Link, { to: item.route }, item.label)))))))));
const AccordionContainer = ({ children, title, }) => (React.createElement(Accordion, null,
    React.createElement(Accordion.Toggle, { as: "div", className: "navbar-header", eventKey: "menu" },
        React.createElement("button", { type: "button", className: "navbar-toggle" },
            React.createElement("span", { className: "sr-only" }, "Toggle navigation"),
            React.createElement("span", { className: "icon-bar" }),
            React.createElement("span", { className: "icon-bar" }),
            React.createElement("span", { className: "icon-bar" })),
        React.createElement("a", { role: "button", className: "navbar-brand" }, title)),
    children));
const CommonMenu = ({ unreadEvents }) => {
    const clientItems = [
        { route: ApplicationRoutes.aboutUs, label: 'About Us' },
        { route: ApplicationRoutes.services, label: 'Services' },
        { route: ApplicationRoutes.courtInformation, label: 'Court Information' },
        { route: ApplicationRoutes.contact, label: 'Contact' },
        {
            route: ApplicationRoutes.clientRequestAppearance,
            label: 'Request an Appearance',
        },
    ];
    const adminItems = [
        { route: ApplicationRoutes.events, label: actionsBraces(unreadEvents) },
        { route: ApplicationRoutes.adminManageInbox, label: 'Notifications' },
        { route: ApplicationRoutes.messages, label: 'Messages' },
        { route: ApplicationRoutes.adminManageLog, label: 'Log' },
        { route: ApplicationRoutes.adminManageCoupon, label: 'Promotional Codes' },
        {
            route: ApplicationRoutes.adminManageAppearances,
            label: 'All Appearances',
        },
        {
            route: ApplicationRoutes.adminManageDueAppearances,
            label: 'Payment Due Appearances',
        },
        { route: ApplicationRoutes.logout, label: 'Logout' },
    ];
    return (React.createElement(AccordionContainer, { title: "Menu" },
        React.createElement(RoleBasedMenu, { role: [UserRole.CLIENT, UserRole.ATTORNEY], items: clientItems }),
        React.createElement(RoleBasedMenu, { role: [UserRole.ADMIN, UserRole.ADMIN_LIMITED], items: adminItems })));
};
const MyAccountMenu = ({ unreadEvents }) => {
    const clientAccountItems = [
        { route: ApplicationRoutes.clientDashboard, label: 'My Dashboard' },
        { route: ApplicationRoutes.events, label: actionsBraces(unreadEvents) },
        {
            route: ApplicationRoutes.clientRequestAppearance,
            label: 'Schedule Appearance',
        },
        {
            route: ApplicationRoutes.clientManageAppearance,
            label: 'Manage My Appearances',
        },
        {
            route: ApplicationRoutes.clientManageTemplate,
            label: 'Manage Templates',
        },
        {
            route: ApplicationRoutes.clientSubmittedAppearance,
            label: 'Appearance Reports',
        },
        { route: ApplicationRoutes.messages, label: 'Messages' },
        {
            route: ApplicationRoutes.clientViewPayments,
            label: 'Appearance Receipts and Invoices',
        },
        { route: ApplicationRoutes.clientAccount, label: 'Manage My Account' },
        { url: 'https://lawbeez.com', label: 'Hire Freelance Attorney' },
    ];
    const attorneyAccountItems = [
        { route: ApplicationRoutes.attorneyDashboard, label: 'My Dashboard' },
        { route: ApplicationRoutes.events, label: actionsBraces(unreadEvents) },
        {
            route: ApplicationRoutes.attorneyAvailableAppearance,
            label: 'View Available Appearances',
        },
        {
            route: ApplicationRoutes.attorneyManageAppearance,
            label: 'Manage My Appearances',
        },
        {
            route: ApplicationRoutes.attorneySubmitResults,
            label: 'Submit Appearance Results',
        },
        { route: ApplicationRoutes.messages, label: 'Messages' },
        { route: ApplicationRoutes.attorneyAccount, label: 'Manage My Account' },
        { url: 'https://lawbeez.com', label: 'Hire Freelance Attorney' },
    ];
    return (React.createElement(AccordionContainer, { title: "My Account" },
        React.createElement(RoleBasedMenu, { role: UserRole.CLIENT, items: clientAccountItems }),
        React.createElement(RoleBasedMenu, { role: UserRole.ATTORNEY, items: attorneyAccountItems })));
};
const PrivateMenu = ({ unreadEvents }) => {
    return (React.createElement("div", { className: "res-men" },
        React.createElement(CommonMenu, { unreadEvents: unreadEvents }),
        React.createElement(MyAccountMenu, { unreadEvents: unreadEvents }),
        React.createElement(Link, { to: ApplicationRoutes.logout, className: "sign-link sign-out-link" }, "Logout")));
};
export default PrivateMenu;
