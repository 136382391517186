import { parseMatches } from 'utils/parseMatches';
const relativeProtocol = '//';
const protocolToUse = 'https://';
const capacitorProtocol = 'capacitor:';
const protocolFull = capacitorProtocol + relativeProtocol;
const debug = false;
let interceptorActive = false;
// eslint-disable-next-line func-names
(function (window, document, NativeImage, ProxyClass, XMLHttpRequestClass) {
    if (window.location.protocol !== capacitorProtocol && !debug) {
        return;
    }
    // eslint-disable-next-line no-unused-expressions,no-console
    debug && console.log('Using URL mutator');
    // eslint-disable-next-line consistent-return
    function urlMutator(url) {
        if (!url) {
            return url;
        }
        const matches = parseMatches(url);
        if (matches.length !== 3) {
            return url;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [full, protocol, host] = matches;
        if (protocol === protocolFull || protocol === relativeProtocol) {
            const parts = host.split('.');
            if (parts.length === 1) {
                return url; // Not a domain
            }
            if (['svg', 'html', 'js', 'json', 'jpeg', 'jpg', 'png', 'ico', 'txt'].includes(parts[parts.length - 1])) {
                return url; // A filename in root dir TODO: more extensions?
            }
            const result = [protocolToUse, url.substr(protocol.length)].join('');
            // eslint-disable-next-line no-console,no-unused-expressions
            debug && console.warn('mutating url', url, 'to', result);
            return result;
        }
        return url;
    }
    function overrideCreateElementFor(d) {
        const createElementBackup = d.createElement;
        // eslint-disable-next-line no-multi-assign,no-param-reassign
        d.createElement = d.constructor.prototype.createElement = (tagName, options) => {
            const scriptElt = createElementBackup.call(document, tagName, options);
            if (tagName.toLowerCase() !== 'script' && tagName.toLowerCase() !== 'iframe') {
                return scriptElt;
            }
            const originalSetAttribute = scriptElt.setAttribute.bind(scriptElt);
            Object.defineProperties(scriptElt, {
                src: {
                    get() {
                        return scriptElt.getAttribute('src');
                    },
                    set(value) {
                        const mutated = urlMutator(value);
                        // eslint-disable-next-line no-unused-expressions,no-console
                        debug && console.log('script element overriden ', value, mutated);
                        originalSetAttribute('src', mutated || value);
                        return true;
                    },
                },
            });
            if (tagName.toLowerCase() === 'iframe') {
                const iframe = scriptElt;
                iframe.src = 'about:blank'; /*
                console.log('Overriding iframe', scriptElt.contentWindow);
                let storedDoc: Document | null;
                let storedWin: Window | null;
                Object.defineProperties(scriptElt, {
                  contentDocument: {
                    get() {
                      console.log(this, this.contentDocument);
                      return storedDoc;
                    },
                    set(doc: Document) {
                      debug && console.log('doc updated');
                      storedDoc = doc;
                      overrideCreateElementFor(storedDoc);
                      return true;
                    },
                  },
                  contentWindow: {
                    get() {
                      console.log(this, this.contentWindow);
                      return storedDoc;
                    },
                    set(wind: Window) {
                      debug && console.log('window updated', wind);
                      storedWin = wind;
                      // overrideCreateElementFor(storedDoc);
                      return true;
                    },
                  },
                }); */
            }
            return scriptElt;
        };
    }
    // eslint-disable-next-line no-param-reassign
    overrideCreateElementFor(document);
    class FakeImage {
        constructor(width, height) {
            const nativeImage = new NativeImage(width, height);
            const handler = {
                set(target, prop, value) {
                    if (prop === 'src') {
                        // eslint-disable-next-line no-console,no-unused-expressions
                        debug && console.log('image element overriden ', value, urlMutator(value));
                        // eslint-disable-next-line no-param-reassign
                        value = urlMutator(value) || value;
                    }
                    // eslint-disable-next-line no-return-assign
                    return nativeImage[prop] = value;
                },
                get(target, prop) {
                    let result = target[prop];
                    if (typeof result === 'function') {
                        result = result.bind(target);
                    }
                    return result;
                },
            };
            const proxy = new ProxyClass(nativeImage, handler);
            try {
                proxy[Symbol.toStringTag] = 'HTMLImageElement';
                // eslint-disable-next-line no-empty
            }
            catch (e) {
            }
            // @ts-ignore
            FakeImage.prototype[Symbol.toStringTag] = NativeImage.prototype.toString();
            return proxy;
        }
    }
    Object.defineProperty(FakeImage, 'name', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: 'Image',
    });
    Object.defineProperty(FakeImage, 'toString', {
        enumerable: true,
        configurable: false,
        writable: true,
        value() {
            return NativeImage.toString();
        },
    });
    // eslint-disable-next-line no-param-reassign
    window.Image = FakeImage;
    if (XMLHttpRequestClass) {
        const { open } = XMLHttpRequestClass.prototype;
        // eslint-disable-next-line no-param-reassign,func-names
        XMLHttpRequestClass.prototype.open = function (method, url, async, username, password) {
            const mutated = urlMutator(url);
            if (mutated) {
                // eslint-disable-next-line no-param-reassign
                url = mutated;
            }
            if (typeof async === 'undefined') {
                return open.call(this, method, url, true);
            }
            return open.call(this, method, url, async, username, password);
        };
    }
    const mutateFetch = (w) => {
        if (w.fetch) {
            const { fetch } = w;
            // eslint-disable-next-line no-param-reassign,func-names,no-multi-assign
            w.constructor.prototype.fetch = w.fetch = function (request, config) {
                if (typeof request === 'string') {
                    const mutated = urlMutator(request);
                    if (mutated) {
                        // eslint-disable-next-line no-param-reassign
                        request = mutated;
                    }
                    return fetch(request, config);
                }
                const mutated = urlMutator(request && request.url);
                if (mutated) {
                    request.url = mutated;
                }
                return fetch(request, config);
            };
        }
    };
    mutateFetch(window);
    interceptorActive = true;
}(window, document, Image, Proxy, XMLHttpRequest));
export default () => interceptorActive;
