import { ContentWrapper } from 'components/ContentWrapper';
import Page from 'components/Page';
import { Success } from 'components/Success';
import { isMobileApp } from 'constants/isMobileApp';
import { LoginForm } from 'containers/Login/LoginForm';
import { ApplicationRoutes } from 'containers/System/routes';
import { defaultEnvId, environments, getEnv, resetEnv, setEnv, } from 'environments';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { selectIsLoggedIn } from 'redux/auth/selectors';
export const Login = ({ isAdmin, isAttorney }) => {
    const changeEnv = React.useCallback((e) => {
        if (e.currentTarget.value) {
            setEnv(e.currentTarget.value);
        }
        else {
            resetEnv();
        }
    }, []);
    const [showEnv, setShowEnv] = React.useState(getEnv().id !== defaultEnvId);
    const [touchCounter, setTouchCounter] = React.useState({ at: 0, times: 0 });
    const otherEnv = getEnv().id !== defaultEnvId;
    const onClick = React.useCallback((e) => {
        if (e.detail === 3) {
            setShowEnv(true);
        }
    }, []);
    const onTouchEnd = React.useCallback(() => {
        if (touchCounter.times === 0 || touchCounter.at > Date.now() - 500) {
            setTouchCounter({ times: touchCounter.times + 1, at: Date.now() });
        }
        else {
            setTouchCounter({ times: 0, at: 0 });
        }
        if (touchCounter.times === 3) {
            setShowEnv(true);
        }
    }, [touchCounter]);
    const auth = useSelector(selectIsLoggedIn);
    if (auth) {
        const redirectTo = window.location.search.startsWith('?returnTo=') ? window.location.search.substr(10) : null;
        if (redirectTo) {
            return React.createElement(Redirect, { to: redirectTo });
        }
        if (isAdmin) {
            return React.createElement(Redirect, { to: ApplicationRoutes.adminManageAppearances });
        }
        if (isAttorney) {
            return React.createElement(Redirect, { to: ApplicationRoutes.attorneyDashboard });
        }
        return React.createElement(Redirect, { to: ApplicationRoutes.clientDashboard });
    }
    return (React.createElement(Page, { title: "Login| Court Appearance Professionals", description: "Schedule your appearance coverage anytime from any device!", keywords: "Attorney Login , Client Login , Login Attorney , Login Client" },
        React.createElement(ContentWrapper, { className: "col-lg-6 login-wrap" },
            React.createElement("div", { className: "page-heading", style: { textAlign: 'center' } },
                React.createElement("h1", { "data-testid": "sign-in-title", onClick: onClick, onTouchEnd: onTouchEnd }, "Login to your Account")),
            React.createElement("div", { className: "col-lg-12 col-md-12 col-sm-12 login-form" },
                showEnv && (React.createElement("div", { className: "select-style" },
                    React.createElement("select", { "data-testid": "environment-selector", onChange: changeEnv },
                        environments.map((e) => React.createElement("option", { value: e.id, key: e.id, selected: getEnv().id === e.id }, e.title)),
                        React.createElement("option", { value: "" }, "Reset To Default")))),
                otherEnv && React.createElement(Success, { message: "Current Environment is Non Default" }),
                React.createElement(LoginForm, { recapcha: true, initial: { rememberMe: isMobileApp } })),
            React.createElement("div", { className: "clearfix" }),
            !isAdmin && (React.createElement("div", { className: "login-bottom" },
                React.createElement("span", null, "Don't have an account?"),
                isAttorney && (React.createElement(Link, { to: ApplicationRoutes.attorneySignUp, title: "Attorney Signup", className: "button" },
                    React.createElement("input", { type: "button", className: "reg-btn", value: "REGISTER" }))),
                !isAttorney && (React.createElement(Link, { to: ApplicationRoutes.clientSignUp, title: "Client Signup", className: "button" },
                    React.createElement("input", { type: "button", className: "reg-btn", value: "REGISTER" }))))))));
};
