import { Logger } from 'logger';
import { isDebug } from 'utils/isDebug';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
export const ENV_KEY = 'env';
export const GA_ENABLED = false;
export const OLARK_SITE_ID = process.env.OLARK_SITE_ID || '1865-272-10-5679';
export const SENTRY_DNS = process.env.SENTRY_DNS || 'https://edd6567bf9f44849a3f79c3debb99de8@sentry.attorneysinmotion.com/5';
export const TINY_MCE_KEY = process.env.TINY_MCE_KEY || '8rj22uby38bh84w5hao46pscz7vc45pp2wwflatibv60t742';
export const GOOGLE_SITE_VERIFY = process.env.GOOGLE_SITE_VERIFY || 'lmeu4a1UoNGrdCF3P-Xk1YInBXmNBVnIA-61wba_SQ8';
export const MS_VALIDATE = process.env.MS_VALIDATE || 'F15586C16204E2C5D9EDF20C463C3377';
export const environments = [
    {
        host: 'https://aimdev.spiral.world',
        id: 'development',
        title: 'Development',
        apiDomain: 'https://api.aimdev.spiral.world',
        reCaptchaKey: '6LdTEr8UAAAAAIMPdQbHi_cJALaeAwVSY6XJqldh',
        googleTrackingKey: 'UA-43895933',
        luckyOrangeKey: '88440',
    },
    {
        host: 'https://attorneys-staging.com/',
        id: 'staging',
        title: 'Staging',
        apiDomain: 'https://api.attorneys-staging.com',
        reCaptchaKey: '6LeuyFomAAAAAAlRW3NX5UUMwQuTEUULjrrf7-X0',
        googleTrackingKey: 'UA-43895933',
        luckyOrangeKey: '88440',
    },
    {
        host: 'https://attorneysinmotion.com',
        id: 'production',
        title: 'Production',
        apiDomain: 'https://api.attorneysinmotion.com',
        reCaptchaKey: '6Le9ERopAAAAAFDTmEfNWU5h56K_hwlbAuTeURxJ',
        googleTrackingKey: 'UA-43895933',
        luckyOrangeKey: '88440',
    },
    {
        host: 'https://new.attorneysinmotion.com',
        id: 'preprod',
        title: 'new.attorneysinmotion',
        apiDomain: 'https://api.new.attorneysinmotion.com',
        reCaptchaKey: '6Ldm5UoUAAAAAB4Nb6MoeN6r4wcehxw4B3d1c8XI',
        googleTrackingKey: 'UA-43895933',
        luckyOrangeKey: '88440',
    },
    {
        host: 'https://admin.attorneysinmotion.com',
        id: 'admin',
        title: 'Admin Prod',
        apiDomain: 'https://api.attorneysinmotion.com',
        reCaptchaKey: '6Ldm5UoUAAAAAB4Nb6MoeN6r4wcehxw4B3d1c8XI',
        googleTrackingKey: 'UA-43895933',
        luckyOrangeKey: '88440',
    },
    {
        host: 'https://admin.attorneysinmotion.com',
        id: 'adminpreprod',
        title: 'Admin PreProd',
        apiDomain: 'https://api.new.attorneysinmotion.com',
        reCaptchaKey: '6Ldm5UoUAAAAAB4Nb6MoeN6r4wcehxw4B3d1c8XI',
        googleTrackingKey: 'UA-43895933',
        luckyOrangeKey: '88440',
    },
];
export const defaultEnvId = process.env.WEB_API_ENV || 'production';
const findEnv = (id) => environments.find((e) => e.id === id);
let overrideFromEnv = true;
if (!findEnv(defaultEnvId)) {
    throw new Error('Invalid environment specified');
}
const readEnv = () => {
    let id = defaultEnvId;
    try {
        id = localStorage.getItem(ENV_KEY) || id;
        overrideFromEnv = false;
        // eslint-disable-next-line no-empty
    }
    catch (e) {
    }
    if (!findEnv(id)) {
        id = defaultEnvId;
    }
    return id;
};
const defaultEnvironment = readEnv();
const calcEnv = (env) => (overrideFromEnv ? {
    id: env.id,
    title: env.title,
    host: process.env.WEB_DOMAIN ? `https://${process.env.WEB_DOMAIN}` : env.host,
    apiDomain: process.env.API_HOST || env.apiDomain,
    reCaptchaKey: process.env.RECAPTCHA || env.reCaptchaKey,
    googleTrackingKey: process.env.GOOGLE_KEY || env.googleTrackingKey,
    luckyOrangeKey: process.env.LO_KEY || env.luckyOrangeKey,
} : Object.assign({}, env));
let currentEnvironmentCalculated = calcEnv(findEnv(defaultEnvironment));
export const getEnv = () => currentEnvironmentCalculated;
export const setEnv = (id, reload = true) => {
    if (findEnv(id)) {
        currentEnvironmentCalculated = calcEnv(findEnv(id));
        try {
            localStorage.setItem(ENV_KEY, id);
            if (reload) {
                window.location.reload();
            }
            if (Storage) {
                Storage.set({
                    key: ENV_KEY,
                    value: id,
                });
            }
            // eslint-disable-next-line no-empty
        }
        catch (e) {
        }
    }
    else {
        Logger.error('Unknown env id', { id });
    }
};
export const resetEnv = () => {
    currentEnvironmentCalculated = calcEnv(findEnv(defaultEnvId));
    try {
        localStorage.removeItem(ENV_KEY);
        if (Storage) {
            Storage.remove({
                key: ENV_KEY,
            });
        }
        window.location.reload();
        // eslint-disable-next-line no-empty
    }
    catch (e) {
    }
};
if (isDebug) {
    global.debugSetEnv = setEnv;
}
