import React from 'react';
import { Link } from 'react-router-dom';
import { ApplicationRoutes } from 'containers/System/routes';
import Tooltip from 'components/Tooltip';
const AttorneySideBar = () => (React.createElement("ul", { className: "menu-left-bx" },
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "attorney-available-appearance", content: "View\u00A0Available\u00A0Appearances" },
            React.createElement(Link, { to: ApplicationRoutes.attorneyAvailableAppearance },
                React.createElement("span", { className: "men-1" })))),
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "attorney-manage-appearance", content: "Manage\u00A0My\u00A0Appearances" },
            React.createElement(Link, { to: ApplicationRoutes.attorneyManageAppearance },
                React.createElement("span", { className: "men-2" })))),
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "attorney-submitted-results", content: "Submit\u00A0Appearance\u00A0Results" },
            React.createElement(Link, { to: ApplicationRoutes.attorneySubmitResults },
                React.createElement("span", { className: "men-3" })))),
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "messages", content: "Messages" },
            React.createElement(Link, { to: ApplicationRoutes.messages },
                React.createElement("span", { className: "men-4" })))),
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "client-account", content: "Manage\u00A0My\u00A0Account" },
            React.createElement(Link, { to: ApplicationRoutes.attorneyAccount },
                React.createElement("span", { className: "men-6" })))),
    React.createElement("li", null,
        React.createElement(Tooltip, { id: "logout", content: "Logout" },
            React.createElement(Link, { to: ApplicationRoutes.logout },
                React.createElement("span", { className: "men-7" }))))));
export default AttorneySideBar;
