import React from 'react';
import Copyright from 'components/Copyright';
import Menu from './Menu';
import Links from './Links';
const Footer = () => (React.createElement("div", { className: "container-fluid no-pad" },
    React.createElement("div", { className: "container footer" },
        React.createElement("div", { className: "col-lg-12 no-pad" },
            React.createElement("div", { className: "footer-container" },
                React.createElement(Menu, null),
                React.createElement(Links, null),
                React.createElement(Copyright, null))))));
export default Footer;
