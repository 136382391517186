import { IS_SSR } from 'constants/isssr';
import * as React from 'react';
import { Spinner } from 'components/Spinner';
import { prerenderedLoadable } from 'utils/prefetch';
const TwitterChat = () => {
    const [ready, setReady] = React.useState(false);
    React.useEffect(() => {
        setTimeout(() => setReady(!IS_SSR && true), 2000);
    }, []);
    if (!ready) {
        return null;
    }
    const TwitterChatRaw = prerenderedLoadable(() => import(/* webpackChunkName: "TwitterChat" */ 'components/TwitterChat/TwitterChat'));
    return (React.createElement(React.Suspense, { fallback: React.createElement(Spinner, null) },
        React.createElement(TwitterChatRaw, null)));
};
export default TwitterChat;
