var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { axiosInstance } from 'client/axiosInstance';
import { API_PATHES } from 'client/constants';
import { Logger } from 'logger';
export const getUnreadNotificationCount = () => __awaiter(void 0, void 0, void 0, function* () {
    const axios = axiosInstance();
    try {
        const result = yield axios.post(API_PATHES.push.unreadCount);
        return result.data.counter;
    }
    catch (e) {
        Logger.error('Cant read actions count', e);
        return 0;
    }
});
export const markNotificationRead = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const axios = axiosInstance();
    try {
        yield axios.post(API_PATHES.push.markReadList, { pushEventIds: [id] });
        return yield getUnreadNotificationCount();
    }
    catch (e) {
        Logger.error('Cant mark as read', e);
        return 0;
    }
});
