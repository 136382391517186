import { createAction, createAsyncAction } from 'typesafe-actions';
export const loginRequestAction = createAsyncAction('@auth/REQUEST', '@auth/SUCCESS', '@auth/FAILED')();
export const refreshTokenAction = createAsyncAction('@auth/TOKEN/REQUEST', '@auth/TOKEN/SUCCESS', '@auth/TOKEN/FAILED')();
export const logoutAction = createAction('@auth/LOGOUT');
export const deauthoriseAction = createAction('@auth/DEAUTHORISE');
export const afterLogoutAction = createAction('@auth/AFTER_LOGOUT');
export const otherTabLogin = createAction('@auth/OTHER_TAB_LOGIN', (action) => (auth) => action(auth));
export const otherTabLogout = createAction('@auth/OTHER_TAB_LOGOUT');
export const afterAuthIsOk = createAction('@auth/AFTER_AUTH', (action) => (auth) => action(auth));
export const resetAction = createAction('@auth/RESET');
export const enableDebugAdmin = createAction('@auth/DEBUG_ADMIN_UI');
