import 'utils/interceptor';
import { Capacitor, Plugins } from '@capacitor/core';
import { initAuthStorage } from 'client/authStorage';
import { setNonAuthorisedCallback } from 'client/axiosInstance';
import { GAWidget } from 'components/GoogleAnalyticsLoader';
import { LOWidget } from 'components/LuckyOrangeLoader';
import { OlarkWidget } from 'components/Olark';
import { isMobileApp } from 'constants/isMobileApp';
import { IS_SSR } from 'constants/isssr';
import { PushNotificationPopup } from 'containers/Modals/ViewPushNotification';
import { GA_ENABLED, getEnv, SENTRY_DNS } from 'environments';
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Router } from 'containers/Router';
import { deauthoriseAction } from 'redux/auth/actions';
import { startStore } from 'redux/store';
import { initialState } from 'redux/initialState';
import { ToastContainer } from 'react-toastify';
import { longDateWSec } from 'utils/date';
import { Logger } from './logger';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/styles/index.css';
let sentryActive = false;
if (!IS_SSR && !(window.location.host === 'localhost:3000' && !isMobileApp)) {
    sentryActive = true;
    Sentry.init({
        dsn: SENTRY_DNS,
        environment: `${isMobileApp ? Capacitor.platform : window.location.host}:${getEnv().id}`,
    });
}
// known issue with ionic apps on ios: https://forum.ionicframework.com/t/ionic-ionic-vew-and-hidekeyboardaccessorybar/118055
if (isMobileApp && Capacitor.platform === 'ios') {
    Plugins.Keyboard.setAccessoryBarVisible({ isVisible: true });
}
initAuthStorage()
    .then(() => {
    const history = createBrowserHistory();
    const store = startStore(initialState, history);
    // Tell react-snap how to save Redux state
    window.snapSaveState = () => ({
        ReactSnapRedux: store.getState(),
    });
    setNonAuthorisedCallback(() => store.dispatch(deauthoriseAction()));
    if (!isMobileApp) {
        window.addEventListener('error', (e) => {
            const reload = e.message.startsWith('Uncaught ChunkLoadError: Loading chunk');
            if (reload && window.location.hash !== `#${process.env.BUILD_NO}`) {
                window.location.hash = `${process.env.BUILD_NO}`;
                window.location.reload();
            }
            else if (sentryActive) {
                Sentry.captureException(new Error('Refreshing website to fix chunks failed'));
            }
        });
    }
    const App = () => (React.createElement(React.Fragment, null,
        React.createElement(Provider, { store: store },
            React.createElement(IntlProvider, { locale: "en" },
                React.createElement(React.Fragment, null,
                    React.createElement(ConnectedRouter, { history: history },
                        React.createElement(Router, null)),
                    !IS_SSR && React.createElement(OlarkWidget, null),
                    GA_ENABLED && React.createElement(GAWidget, null),
                    React.createElement(LOWidget, null),
                    React.createElement(PushNotificationPopup, null),
                    React.createElement(ToastContainer, null))))));
    const MOUNT_NODE = document.getElementById('app');
    // eslint-disable-next-line react/no-render-return-value
    const render = () => MOUNT_NODE.hasChildNodes()
        ? ReactDOM.hydrate(React.createElement(App, null), MOUNT_NODE)
        : ReactDOM.render(React.createElement(App, null), MOUNT_NODE);
    if (module.hot) {
        Logger.debug('Using hot reload');
        module.hot.accept(['containers/Router'], () => {
            ReactDOM.unmountComponentAtNode(MOUNT_NODE);
            render();
        });
    }
    render();
})
    .catch((e) => {
    Sentry.captureException(e);
    Logger.error('Failed to start app', e);
});
Logger.info(`Application Version ${process.env.VERSION}`, {
    buildDate: longDateWSec(new Date(+(process.env.BUILD_NO || '0'))),
});
