import { Plugins, Capacitor } from '@capacitor/core';
import { Logger } from 'logger';
import React from 'react';
import aimLogo from 'assets/images/aim-big.png';
import './styles.css';
const { Browser } = Plugins;
const isAndroid = Capacitor.platform === 'android';
const PLAY_MARKET_URI = 'itms-apps://itunes.apple.com/app/id1493170905';
const GOOGLE_PLAY_URI = 'market://details?id=com.attorneysinmotion.app';
const PLAY_MARKET_BROWSER_URL = 'https://apps.apple.com/us/app/apple-store/id1493170905';
const GOOGLE_PLAY_BROWSER_URL = 'https://play.google.com/store/apps/details?id=com.attorneysinmotion.app';
export const ApplicationUpdate = () => {
    const openStore = React.useCallback(() => {
        const marketUrl = isAndroid ? GOOGLE_PLAY_URI : PLAY_MARKET_URI;
        Browser.open({ url: marketUrl }).catch((e) => {
            Logger.debug(e, 'Cannot open store using uri', Capacitor.platform);
            const browserMarketUrl = isAndroid ? GOOGLE_PLAY_BROWSER_URL : PLAY_MARKET_BROWSER_URL;
            Browser.open({ url: browserMarketUrl });
        });
    }, []);
    return (React.createElement("div", { className: "app-update" },
        React.createElement("img", { src: aimLogo, alt: "AIM logo" }),
        React.createElement("p", { className: "multiline-text" },
            "There is a new version available for download.",
            React.createElement("br", null),
            "Please install the app."),
        React.createElement("button", { type: "button", className: "s-btn", onClick: openStore }, "Install")));
};
