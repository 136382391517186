export const actionsCountLong = (actions) => {
    if (actions) {
        return (actions === 1) ? 'new action' : `${actions} actions`;
    }
    return 'no actions';
};
export const actionsBraces = (actions) => {
    if (actions) {
        return `Actions (${actions})`;
    }
    return 'Actions';
};
